import { type FC, useEffect } from 'react';

import moment from 'moment';

import {
  fileUpdateInitial,
  fileUpdateValidation,
  useFileUpdate,
} from '@api/files/useFileUpdate';
import { Button, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';

type FileUpdateModalProps = {
  fileNameKz?: string;
  fileNameRu?: string;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const FileUpdateModal: FC<FileUpdateModalProps> = ({
  fileNameKz = '',
  fileNameRu = '',
  id = '',
  isOpen,
  onClose,
}) => {
  const form = useForm({
    initialValues: fileUpdateInitial,
    validate: yupResolver(fileUpdateValidation),
  });

  const editFile = useFileUpdate({
    ...form.values,
    fileId: Number(id),
    fileNameKz: `${form.values.fileNameKz} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`,
    fileNameRu: `${form.values.fileNameRu} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`,
  });

  useEffect(() => {
    if (fileNameKz && fileNameRu && id) {
      form.setValues({
        fileId: Number(id),
        fileNameKz:
          fileNameKz.substring(0, fileNameKz.indexOf('(')) || fileNameKz,
        fileNameRu:
          fileNameRu.substring(0, fileNameRu.indexOf('(')) || fileNameRu,
      });
    }
  }, [fileNameKz, fileNameRu, id]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Редактировать наименование файла"
    >
      <form
        onSubmit={form.onSubmit((values) => {
          editFile.mutateAsync().then(() => {
            notify('success', 'Наименование файлов успешно изменены!');
            onClose();
          });
        })}
      >
        <div className="mb-5 grid grid-cols-2 items-baseline gap-4">
          <TextInput
            className="mt-4"
            description={`${form.values.fileNameKz} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`}
            label="Наименование файла на казахском"
            name="fileNameKz"
            withAsterisk
            {...form.getInputProps('fileNameKz')}
          />
          <TextInput
            className="mt-4"
            description={`${form.values.fileNameRu} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`}
            label="Наименование файла на русском"
            name="fileNameRu"
            withAsterisk
            {...form.getInputProps('fileNameRu')}
          />
        </div>

        <div className="flex flex-row justify-end">
          <div className="flex justify-end gap-4 pt-0">
            <Button className="!w-[150px]" onClick={onClose} variant="subtle">
              Отменить
            </Button>
            <Button
              className="!w-[150px]"
              disabled={!form.isDirty()}
              loading={editFile.isLoading}
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
