import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { ActionIcon, Card, Group, Image, Menu, rem, Text } from '@mantine/core';
import { employeesFilters } from '@modules/employees/consts/filters/employeesFilters';
import {
  employeePageActions,
  IEmployeePageActions,
} from '@modules/employees/consts/pageActions/IEmployeePageActions';
import { IconDots, IconEdit, IconEye, IconTrash } from '@tabler/icons-react';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IEmployee } from '@/types/IEmployee';

export default function EmployeeCard({ employee }: { employee: IEmployee }) {
  const navigate = useNavigate();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IEmployeePageActions
  >(employeesFilters, employeePageActions);

  return (
    <Card radius="md" shadow="sm" w={250} withBorder>
      <Card.Section>
        <Image
          fallbackSrc="https://placehold.co/600x600/F1F4F7/1A2434?text=Не удалось загрузить фото"
          fit="cover"
          h={270}
          src={employee.photo}
        />
      </Card.Section>

      <Card.Section className="h-[100%]" inheritPadding py="md" withBorder>
        <Group className="h-[100%]" justify="space-between">
          <div>
            <Text fw={600}>
              {language == 'kz'
                ? `${employee.firstNameKz} ${employee.lastNameKz} ${employee.surnameKz}`
                : `${employee.firstNameRu} ${employee.lastNameRu} ${employee.surnameRu}`}
            </Text>
            <Text c="dimmed" mt="xs" size="sm">
              {language == 'kz' ? employee.jobTitleKz : employee.jobTitleRu}
            </Text>
          </div>
          <Menu position="bottom-end" shadow="sm" withinPortal>
            <Menu.Target>
              <ActionIcon color="gray" variant="subtle">
                <IconDots style={{ height: rem(16), width: rem(16) }} />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Link to={`/employees/${employee.id}`}>
                <Menu.Item
                  leftSection={
                    <IconEye style={{ height: rem(14), width: rem(14) }} />
                  }
                >
                  Просмотреть
                </Menu.Item>
              </Link>
              <Menu.Item
                leftSection={
                  <IconEdit style={{ height: rem(14), width: rem(14) }} />
                }
                onClick={() => navigate(`/employees/edit/${employee.id}`)}
              >
                Редактировать
              </Menu.Item>
              <Menu.Item
                color="red"
                leftSection={
                  <IconTrash style={{ height: rem(14), width: rem(14) }} />
                }
                onClick={() => {
                  setPartialActioned({
                    action: 'delete',
                    actionId: String(employee.id) ?? '',
                  });
                }}
              >
                Удалить
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Card.Section>
    </Card>
  );
}
