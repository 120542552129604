import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';

type IStatementAmountParams = {
  amount: string;
  amountComment: string;
  id: number;
};

type StatementAmountResponse = void;

export function useStatementAmount() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<StatementAmountResponse>,
    AxiosError,
    IStatementAmountParams
  >(
    (payload) =>
      api.post(
        '/contract-service/api/statements/add-amount',
        {},
        {
          params: {
            amount: payload.amount,
            amountComment: payload.amountComment,
            id: payload.id,
          },
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.statement]);
      },
    }
  );
}
