import { t } from 'i18next';

export type ApplicationsStatusEnumKeys = keyof typeof ApplicationsStatusEnum;

export enum ApplicationsStatusEnum {
  IN_PROCESSING_DOCUMENTS = 'Требуется обработка документов',
  IN_PROCESSING_PAYMENT = 'Требуется обработка заявления',
  PAYMENT_SENT = 'Направлена выплата',
  REJECTED = 'Отклонено',
  STATEMENT_ACCEPTED = 'Заявление принято',
  WAITING_FOR_DOCUMENTS = 'Ожидаем документы',
  WAITING_FOR_PAYMENT_APPLICATION = 'Ожидаем заявления на выплату',
}

export enum ApplicationsStatusValueEnum {
  IN_PROCESSING_DOCUMENTS = 'IN_PROCESSING_DOCUMENTS',
  IN_PROCESSING_PAYMENT = 'IN_PROCESSING_PAYMENT',
  PAYMENT_SENT = 'PAYMENT_SENT',
  REJECTED = 'REJECTED',
  STATEMENT_ACCEPTED = 'STATEMENT_ACCEPTED',
  WAITING_FOR_DOCUMENTS = 'WAITING_FOR_DOCUMENTS',
  WAITING_FOR_PAYMENT_APPLICATION = 'WAITING_FOR_PAYMENT_APPLICATION',
}

export const getApplicationsStatuses = () => [
  {
    label: t(`statuses.IN_PROCESSING_DOCUMENTS`),
    value: ApplicationsStatusValueEnum.IN_PROCESSING_DOCUMENTS,
  },
  {
    label: t(`statuses.IN_PROCESSING_PAYMENT`),
    value: ApplicationsStatusValueEnum.IN_PROCESSING_PAYMENT,
  },
  {
    label: t(`statuses.PAYMENT_SENT`),
    value: ApplicationsStatusValueEnum.PAYMENT_SENT,
  },
  {
    label: t(`statuses.STATEMENT_ACCEPTED`),
    value: ApplicationsStatusValueEnum.STATEMENT_ACCEPTED,
  },
  {
    label: t(`statuses.WAITING_FOR_DOCUMENTS`),
    value: ApplicationsStatusValueEnum.WAITING_FOR_DOCUMENTS,
  },
  {
    label: t(`statuses.REJECTED`),
    value: ApplicationsStatusValueEnum.REJECTED,
  },
  {
    label: t(`statuses.WAITING_FOR_PAYMENT_APPLICATION`),
    value: ApplicationsStatusValueEnum.WAITING_FOR_PAYMENT_APPLICATION,
  },
];
