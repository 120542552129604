import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IStatement } from '@/types/IStatement';

type IStatementResponse = IStatement;

export function useStatement({
  ...params
}: IPageAbleRequest & {
  id: number;
}) {
  return useQuery<IStatementResponse, AxiosError>(
    [queryKeys.statement, params],
    () =>
      api
        .get(`/contract-service/api/statements/get-statement-id`, {
          params: { id: params.id },
        })
        .then((res) => res.data)
    // {
    //   enabled:
    //     !!localStorage.getItem(storageKeys.tokenAccess),
    // }
  );
}
