import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useEmployeeCount } from '@api/employees/useEmployeeCount';
import { useEmployees } from '@api/employees/useEmployees';
import { Search } from '@components/atoms/Search';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { IEnumEmployeeTypesKeys } from '@consts/enums/IEnumEmployeeTypes';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, Chip, Group, Pagination, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import EmployeeSkeleton from '../components/atoms/EmployeeSkeleton';
import EmployeeCard from '../components/molecules/EmployeeCard';
import { ConfirmDeleteModal } from '../components/organisms/ConfirmDeleteModal';
import { employeesFilters } from '../consts/filters/employeesFilters';
import {
  employeePageActions,
  IEmployeePageActions,
} from '../consts/pageActions/IEmployeePageActions';

const EmployeesPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [value, setValue] = useState<IEnumEmployeeTypesKeys>('BOARD_DIRECTORS');

  const { actioned, clearParams, filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    IEmployeePageActions
  >(employeesFilters, employeePageActions);

  const employeesQuery = useEmployees({
    ...filtered,
    category: value,
    searchQuery,
  });
  const employees = employeesQuery.data?.content;

  const employeesCountQuery = useEmployeeCount();
  const employeesCount = employeesCountQuery.data?.data;
  const totalEmployeesCount = useMemo(() => {
    if (employeesCount) {
      return (
        employeesCount?.boardDirectorsCount +
        employeesCount?.executivesCount +
        employeesCount?.governanceCount
      );
    }
    return 0;
  }, [employeesCount]);

  const handleTabChange = (value: string) => {
    setValue(value as IEnumEmployeeTypesKeys);
  };

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/employees', title: 'Руководство' }]}
        openMenuKeys={['employees']}
        selectedMenuKeys={['employees']}
        title="Руководство"
      />
      <div className="flex pt-6">
        <Text fw={600} mr={12} size="lg">
          Все сотрудники
        </Text>
        <span className="rounded-2xl bg-[#EBF3FD] px-2 text-[#1F4A62]">
          {totalEmployeesCount}
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="flex flex-col gap-4">
            <div className="col-span-3 mt-5 flex items-baseline justify-between gap-4">
              <Chip.Group
                multiple={false}
                onChange={(value) => handleTabChange(value)}
                value={value}
              >
                <Group className="gap-0" justify="start">
                  <Chip value="BOARD_DIRECTORS" variant="outline">
                    Совет директоров
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {employeesCount?.boardDirectorsCount}
                    </span>
                  </Chip>
                  <Chip value="GOVERNANCE" variant="outline">
                    Правление
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {employeesCount?.governanceCount}
                    </span>
                  </Chip>
                  {/* <Chip value="EXECUTIVES" variant="outline">
                    Руководящие работники
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {employeesCount?.executivesCount}
                    </span>
                  </Chip> */}
                </Group>
              </Chip.Group>
            </div>
            <div className="flex items-baseline justify-end gap-4">
              <Search onChange={setSearchQuery} searchQuery={searchQuery} />
              <Link to="/employees/create">
                <Button leftSection={<IconPlus />}>Добавить сотрудника</Button>
              </Link>
            </div>
            <div className="grid grid-cols-4 gap-2">
              {employeesQuery.isLoading ||
              (!!employees?.length && employeesQuery.isLoading) ? (
                <>
                  <EmployeeSkeleton />
                  <EmployeeSkeleton />
                </>
              ) : !employees?.length ? (
                <div className="col-span-4">
                  <NotFoundResult
                    subTitle="В данном разделе отсутствуют сотрудники"
                    title=""
                  />
                </div>
              ) : (
                employees?.map((employee) => (
                  <EmployeeCard employee={employee} key={employee.id} />
                ))
              )}
            </div>
          </div>

          {employees && employees.length !== 0 && (
            <div className="mt-5 flex justify-between gap-4">
              <Pagination
                classNames={{
                  control:
                    'border-gray-200 border-[#DEE2E6] data-[active=true]:border-primary data-[active=true]:text-white',
                }}
                onChange={(page) => onFilter({ page: page - 1 })}
                total={employeesQuery.data?.totalPages || 1}
                value={
                  typeof filtered.page == 'string'
                    ? Number.parseInt(filtered.page) + 1
                    : Number(filtered.page) + 1 || 1
                }
              />
            </div>
          )}
        </div>
      </div>

      <ConfirmDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />
    </div>
  );
};

export default EmployeesPage;
