import { MRT_Column } from 'mantine-react-table';

import { TableFilter } from '@/types/common/IFilters';
import { ITableContent } from '@/types/common/ITableContent';

import { QueryTableSortIcon } from './QueryTableSortIcon';

interface HeaderInterface<T extends ITableContent> {
  column: MRT_Column<T>;
  tableFilterProps: TableFilter;
}

enum TableSortEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

const checkTextForCharacterIndex = (text: string, index = 0) => {
  const indexCharacter = text.charAt(index);
  const specialCharacters = '!@#$%^&*()_+{}:"<>?|\\-=[];\',./~`';

  return specialCharacters.includes(indexCharacter);
};

export const QueryTableHeader = <T extends ITableContent>({
  column,
  tableFilterProps,
}: HeaderInterface<T>) => {
  const { filtered, filters, onFilter } = tableFilterProps;
  const {
    columnDef: { accessorKey, header, id },
  } = column;
  const dataIndex = typeof accessorKey == 'string' ? accessorKey : id;

  const filteredSortValue = String(filtered['sort']);

  const orderedBy = checkTextForCharacterIndex(filteredSortValue ?? '')
    ? TableSortEnum.DESC
    : TableSortEnum.ASC;

  const sortedBy = checkTextForCharacterIndex(filteredSortValue ?? '')
    ? filteredSortValue?.slice(1, filteredSortValue?.length)
    : filteredSortValue;

  const filter = filters[dataIndex];
  if (filter && filter.sort) {
    return (
      <div className="flex w-full justify-between gap-4 text-gray-500">
        <span>{filter.title || header}</span>
        <QueryTableSortIcon
          dataIndex={dataIndex}
          onFilter={onFilter}
          orderedBy={orderedBy}
          sortedBy={sortedBy}
          sortOrderReversed={filter.sortOrderReversed}
        />
      </div>
    );
  }

  return <span>{header}</span>;
};
