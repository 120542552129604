import { AxiosError, AxiosResponse } from 'axios';
import { object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IContract } from '@/types/IContract';
import { IStatement } from '@/types/IStatement';
// import { IUser } from '@/types/IUser';

export type IStatementCreateRequest = Pick<
  IContract,
  'contractNumber' | 'sumInsured' | 'premiumInsurance' | 'type'
> & {
  acquirer: string;
  beneficiary: string;
  reason:
    | 'LIFE_DEATH_DUE_TO_ILLNESS'
    | 'LIFE_DEATH_DUE_TO_ACCIDENT'
    | 'LIFE_DISABILITY_DUE_TO_ACCIDENT'
    | 'OSNS_LOSS_OF_PROFESSIONAL_ABILITY'
    | 'OSNS_EMPLOYEE_DEATH';
};

type IIStatementCreateResponse = AxiosResponse<IStatement>;

export const statementCreateInitial: IStatementCreateRequest = {
  acquirer: '',
  beneficiary: '',
  contractNumber: '',
  // identifier: '',
  premiumInsurance: '',
  reason: 'LIFE_DEATH_DUE_TO_ILLNESS',
  sumInsured: '',
  type: 'INDIVIDUAL',
};

export const statementCreateValidation: ObjectSchema<IStatementCreateRequest> =
  object({
    acquirer: string().required(),
    beneficiary: string().required(),
    contractNumber: string().required(),
    // identifier: string().required(),
    premiumInsurance: string().required(),
    reason: string<
      | 'LIFE_DEATH_DUE_TO_ILLNESS'
      | 'LIFE_DEATH_DUE_TO_ACCIDENT'
      | 'LIFE_DISABILITY_DUE_TO_ACCIDENT'
      | 'OSNS_LOSS_OF_PROFESSIONAL_ABILITY'
      | 'OSNS_EMPLOYEE_DEATH'
    >().required('Поле обязательно для заполнения'),
    sumInsured: string().required(),
    type: string<'INDIVIDUAL' | 'COMPANY'>().required(
      'Поле обязательно для заполнения'
    ),
  });

export function useStatementCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IIStatementCreateResponse,
    AxiosError,
    IStatementCreateRequest
  >((payload) => api.post('/contract-service/api/statements', payload), {
    onError: (error) => {
      if (error.code == '413') {
        notify('error', 'Что-то пошло не так, повторите попытку позже.');
      }
    },
    onSuccess: () => queryClient.invalidateQueries([queryKeys.statements]),
  });
}
