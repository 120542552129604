export type IEnumEmployeeTypesKeys = keyof typeof IEnumEmployeeType;

export enum IEnumEmployeeType {
  BOARD_DIRECTORS = 'Совет директоров',
  // EXECUTIVES = 'Руководящие работники',
  GOVERNANCE = 'Правление',
}

export const getEmployeeType = () => [
  {
    label: 'Совет директоров',
    value: 'BOARD_DIRECTORS',
  },
  // {
  //   label: 'Руководящие работники',
  //   value: 'EXECUTIVES',
  // },
  {
    label: 'Правление',
    value: 'GOVERNANCE',
  },
];
