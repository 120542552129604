import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment';

import {
  IEnumFileSubcategoryTypes,
  IEnumFileTypes,
} from '@consts/enums/IEnumFileTypes';
import { ActionIcon, Text, Tooltip } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';

import { IFile } from '@/types/IFile';

export const filesColumns: MRT_ColumnDef<IFile>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorFn: (row) => (
      <Tooltip label={row.fileNameKz ?? '-'}>
        <div className="flex items-center">
          <ActionIcon
            className="mr-2"
            onClick={() => {
              const link = document.createElement('a');
              link.download = row.fileKz ?? '';
              link.href = row.fileKz ?? '';
              link.click();
            }}
            variant="subtle"
          >
            <IconDownload
              stroke={1.5}
              style={{ height: '70%', width: '70%' }}
            />
          </ActionIcon>
          <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
            {row.fileNameKz ?? '-'}
          </Text>
        </div>
      </Tooltip>
    ),
    accessorKey: 'fileNameKz',
    header: 'Файл на казахском',
    size: 150,
  },
  {
    accessorFn: (row) => (
      <Tooltip label={row.fileNameRu ?? '-'}>
        <div className="flex items-center">
          <ActionIcon
            className="mr-2"
            onClick={() => {
              const link = document.createElement('a');
              link.download = row.fileRu ?? '';
              link.href = row.fileRu ?? '';
              link.click();
            }}
            variant="white"
          >
            <IconDownload
              stroke={1.5}
              style={{ height: '70%', width: '70%' }}
            />
          </ActionIcon>
          <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
            {row.fileNameRu ?? '-'}
          </Text>
        </div>
      </Tooltip>
    ),
    accessorKey: 'fileNameRu',
    header: 'Файл на русском',
    size: 150,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {IEnumFileTypes[row.category] ?? '-'}
      </span>
    ),
    accessorKey: 'category',
    header: 'Категория',
  },
  {
    accessorFn: (row) => (
      <Tooltip
        label={
          row.subcategory == 'INSURANCE_RATES' ||
          row.subcategory == 'СOMPANY_INFO' ||
          row.subcategory == 'INSURANCE_RULES'
            ? IEnumFileSubcategoryTypes[row.subcategory]
            : (row.subcategory as string)
        }
      >
        <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
          {row.subcategory == 'INSURANCE_RATES' ||
          row.subcategory == 'СOMPANY_INFO' ||
          row.subcategory == 'INSURANCE_RULES'
            ? IEnumFileSubcategoryTypes[row.subcategory]
            : (row.subcategory as string)}
        </Text>
      </Tooltip>
    ),
    accessorKey: 'subcategory',
    header: 'Подкатегория',
    size: 150,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.lastModifiedDate
          ? moment(row.lastModifiedDate).format('DD.MM.YY')
          : '-'}
      </span>
    ),
    accessorKey: 'lastModifiedDate',
    header: 'Последние изменения',
  },
];
