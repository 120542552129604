import { FC, useState } from 'react';

import { useClients } from '@api/clients/useClients';
import { Search } from '@components/atoms/Search';
import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Text } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { clientsColumns } from '../consts/columns/clientsColumns';
import { clientsFilters } from '../consts/filters/clientsFilters';
import {
  clientsPageActions,
  IClientPageActions,
} from '../consts/pageActions/IClientPageActions';

const ClientsPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const { filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    IClientPageActions
  >(clientsFilters, clientsPageActions);

  const clientsQuery = useClients({
    ...filtered,
    searchQuery,
  });

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/clients', title: 'Клиенты' }]}
        openMenuKeys={['clients']}
        selectedMenuKeys={['clients']}
        title="Клиенты"
      />
      <div className="flex pt-6">
        <Text fw={600} mr={12} size="lg">
          Все клиенты
        </Text>
        {/* <span className="rounded-2xl bg-[#EBF3FD] px-2 text-[#1F4A62]">
          {requestsQuery.data?.totalElements}
        </span> */}
      </div>
      <div className="mb-4 flex items-baseline justify-end">
        <Search onChange={setSearchQuery} searchQuery={searchQuery} />
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="flex flex-col gap-4">
            <div className="overflow-x-scroll rounded-xl bg-white p-5">
              <QueryTable
                columns={clientsColumns}
                data={clientsQuery.data}
                empty={{
                  description: 'В данном разделе отсутствуют загруженные файлы',
                  title: '',
                }}
                query={clientsQuery}
                tableFilter={{
                  filtered,
                  filters: clientsFilters,
                  onFilter,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsPage;
