import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNewsPostDelete } from '@api/news/useNewsPostDelete';
import { Button, Modal } from '@mantine/core';

type DeleteAttributeModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmDeleteModal: FC<DeleteAttributeModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const deleteNews = useNewsPostDelete();

  const handleSubmit = () => {
    deleteNews.mutateAsync({ id: Number(id) }).then(() => {
      onClose();
      navigate('/news');
    });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Удалить публикацию"
    >
      <div className="m-6 mx-auto mt-0 max-w-[410px] text-center font-semibold text-gray-600">
        {`Вы уверены, что хотите удалить публикацию?`}
      </div>

      <div className="flex justify-end gap-4 pt-0">
        <Button className="!w-[150px]" onClick={onClose} variant="subtle">
          Нет
        </Button>
        <Button
          className="!w-[150px]"
          loading={deleteNews.isLoading}
          onClick={handleSubmit}
        >
          Да
        </Button>
      </div>
    </Modal>
  );
};
