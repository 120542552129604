import { t } from 'i18next';

export type RequestsStatusEnumKeys = keyof typeof RequestsStatusEnum;

export enum RequestsStatusEnum {
  NEW_APPLICATION = 'Новый',
  PROCESSED_APPLICATION = 'Обработан',
}

export enum RequestsStatusValueEnum {
  NEW_APPLICATION = 'NEW_APPLICATION',
  PROCESSED_APPLICATION = 'PROCESSED_APPLICATION',
}

export const getRequestsStatuses = () => [
  {
    label: t(`statuses.NEW_APPLICATION`),
    value: RequestsStatusValueEnum.NEW_APPLICATION,
  },
  {
    label: t(`statuses.PROCESSED_APPLICATION`),
    value: RequestsStatusValueEnum.PROCESSED_APPLICATION,
  },
];
