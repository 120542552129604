import { type FC, useState } from 'react';

import { useStatementChangeStatus } from '@api/statements/useStatementChangeStatus.ts';
import {
  ApplicationsStatusEnumKeys,
  getApplicationsStatuses,
} from '@consts/enums/IEnumApplicationsStatus';
import { Button, Modal, Select, Textarea } from '@mantine/core';

type ChangeStatementStatusModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  statementStatus: ApplicationsStatusEnumKeys;
};

export const ChangeStatementStatusModal: FC<
  ChangeStatementStatusModalProps
> = ({ id = '', isOpen, onClose, statementStatus }) => {
  const [status, setStatus] = useState<string | null>();
  const [comment, setComment] = useState('');

  const changeStatus = useStatementChangeStatus({
    comment: comment,
    id: +id,
    status: status as ApplicationsStatusEnumKeys,
  });

  const handleSubmit = () => {
    changeStatus.mutateAsync().then(() => onClose());
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Cменить статус заявления"
    >
      <div className="mb-10">
        <Select
          clearable
          comboboxProps={{ withinPortal: false }}
          data={getApplicationsStatuses().map((type) => ({
            label: type.label,
            value: type.value,
          }))}
          defaultValue={statementStatus}
          onChange={setStatus}
          placeholder="Статус"
          value={status}
          withAsterisk
        />
        {status == 'REJECTED' && (
          <Textarea
            className="mt-3"
            minRows={6}
            onChange={(event) => setComment(event.currentTarget.value)}
            placeholder="Укажите причину отказа..."
            value={comment}
          />
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Button onClick={onClose} variant="outline">
          Нет
        </Button>
        <Button loading={changeStatus.isLoading} onClick={handleSubmit}>
          Да
        </Button>
      </div>
    </Modal>
  );
};
