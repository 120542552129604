import { Route, Routes } from 'react-router-dom';

import AdminLayout from '@layouts/AdminLayout';
import AuthLayout from '@layouts/AuthLayout';
import { ClientsRouter } from '@modules/clients/ClientsRouter';
import { EmployeesRouter } from '@modules/employees/EmployeesRouter';
import { FilesRouter } from '@modules/files/FilesRouter';
import { NewsRouter } from '@modules/news/NewsRouter';
import LoginPage from '@modules/pages/LoginPage';
import { RequestsRouter } from '@modules/requests/RequestsRouter';
import { StatementsRouter } from '@modules/statements/StatementsRouter';
import IndexRoute from '@routes/IndexRoute';

const App = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route element={<LoginPage />} path="login" />
      </Route>

      <Route element={<AdminLayout />} path="*">
        <Route element={<IndexRoute />} index />
        <Route element={<NewsRouter />} path="news/*" />
        <Route element={<EmployeesRouter />} path="employees/*" />
        <Route element={<FilesRouter />} path="files/*" />
        <Route element={<StatementsRouter />} path="statements/*" />
        <Route element={<RequestsRouter />} path="requests/*" />
        <Route element={<ClientsRouter />} path="clients/*" />
      </Route>
    </Routes>
  );
};

export default App;
