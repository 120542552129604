import { IEmployeeCreateRequest } from '@api/employees/useEmployeeCreate';
import { IEmployeeUpdateRequest } from '@api/employees/useEmployeeUpdate';
import { INewsPostCreateRequest } from '@api/news/useNewsCreate';
import { INewsUpdateRequest } from '@api/news/useNewsUpdate';
import { createFormContext } from '@mantine/form';

export const [NewsFormProvider, useNewsFormContext, useNewsForm] =
  createFormContext<INewsPostCreateRequest | INewsUpdateRequest>();

export const [
  EmployeesFormProvider,
  useEmployeesFormContext,
  useEmployeesForm,
] = createFormContext<IEmployeeCreateRequest | IEmployeeUpdateRequest>();
