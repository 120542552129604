import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

type IFilesCountResponse = AxiosResponse<{
  corporateDocumentsCount: number;
  financialStatementCount: number;
  informationAboutParticipationAssociationsUnions: number;
  registerOfInsuranceAgentsCount: number;
  remunerationInsuranceAgentsCount: number;
}>;

export function useFilesCount() {
  return useQuery<IFilesCountResponse, AxiosError>([queryKeys.filesCount], () =>
    api.get(`/admin/api/files/get-category-count`)
  );
}
