import { IPageActions } from '@/types/common/IPageActions';

enum IEnumStatementPageActions {
  'change-document-status',
  'change-statement-status',
}

export type IStatementPageActions = {
  action: keyof typeof IEnumStatementPageActions;
  actionId: string;
  actionStatus: string;
};

export const statementPageActions: IPageActions<IStatementPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumStatementPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionStatus: { default: '' },
};
