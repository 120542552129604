import { AxiosError, AxiosResponse } from 'axios';
import { mixed, object, ObjectSchema, string } from 'yup';

import { IEnumPostTypesKeys } from '@consts/enums/IEnumPostTypes';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { INewsPost } from '@/types/INewsPost';

export type INewsPostCreateRequest = Pick<
  INewsPost,
  | 'postType'
  | 'createdAt'
  | 'contentKz'
  | 'contentRu'
  | 'titleKz'
  | 'titleRu'
  | 'imageKz'
  | 'imageRu'
  | 'slugKz'
  | 'slugRu'
> & {
  fileKz: any;
  fileRu: any;
};

type INewsPostCreateResponse = AxiosResponse<INewsPost>;

export const newsPostCreateInitial: INewsPostCreateRequest = {
  contentKz: '',
  contentRu: '',
  createdAt: new Date(),
  fileKz: null,
  fileRu: null,
  imageKz: '',
  imageRu: '',
  postType: 'NEWS',
  slugKz: '',
  slugRu: '',
  titleKz: '',
  titleRu: '',
};

export const newsPostCreateValidation: ObjectSchema<INewsPostCreateRequest> =
  object({
    contentKz: string(),
    contentRu: string(),
    createdAt: string().required('Поле обязательно для заполнения'),
    fileKz: mixed().required('Поле обязательно для заполнения'),
    fileRu: mixed().required('Поле обязательно для заполнения'),
    imageKz: string(),
    imageRu: string(),
    postType: string<IEnumPostTypesKeys>().required(
      'Поле обязательно для заполнения'
    ),
    slugKz: string().required('Поле обязательно для заполнения'),
    slugRu: string().required('Поле обязательно для заполнения'),
    titleKz: string().required('Поле обязательно для заполнения'),
    titleRu: string().required('Поле обязательно для заполнения'),
  });

export function useNewsCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    INewsPostCreateResponse,
    AxiosError,
    INewsPostCreateRequest
  >((payload) => api.post('/admin/api/news', payload), {
    onError: (error) => {
      if (error.code == '413') {
        notify('error', 'Что-то пошло не так, повторите попытку позже.');
      }
    },
    onSuccess: () => queryClient.invalidateQueries([queryKeys.news]),
  });
}
