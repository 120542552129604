import { AxiosError, AxiosResponse } from 'axios';
import { mixed, number, object, ObjectSchema, string } from 'yup';

import { IEnumEmployeeTypesKeys } from '@consts/enums/IEnumEmployeeTypes';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IEmployee } from '@/types/IEmployee';

export type IEmployeeUpdateRequest = Pick<
  IEmployee,
  | 'photo'
  | 'lastNameKz'
  | 'lastNameRu'
  | 'firstNameKz'
  | 'firstNameRu'
  | 'jobTitleKz'
  | 'jobTitleRu'
  | 'additionalInfoKz'
  | 'additionalInfoRu'
  | 'category'
  | 'id'
  | 'surnameKz'
  | 'surnameRu'
  | 'priorId'
> & {
  file: any;
};

type IEmployeeUpdateResponse = void;

export const employeeUpdateInitial: IEmployeeUpdateRequest = {
  additionalInfoKz: '',
  additionalInfoRu: '',
  category: '',
  file: null,
  firstNameKz: '',
  firstNameRu: '',
  id: 0,
  jobTitleKz: '',
  jobTitleRu: '',
  lastNameKz: '',
  lastNameRu: '',
  photo: '',
  priorId: 0,
  surnameKz: '',
  surnameRu: '',
};

export const employeeUpdateValidation: ObjectSchema<IEmployeeUpdateRequest> =
  object({
    additionalInfoKz: string(),
    additionalInfoRu: string(),
    category: string<IEnumEmployeeTypesKeys>().required(
      'Поле обязательно для заполнения'
    ),
    file: mixed().nullable(),
    firstNameKz: string().required('Поле обязательно для заполнения'),
    firstNameRu: string().required('Поле обязательно для заполнения'),
    id: number().required('Поле обязательно для заполнения'),
    jobTitleKz: string().required('Поле обязательно для заполнения'),
    jobTitleRu: string().required('Поле обязательно для заполнения'),
    lastNameKz: string().required('Поле обязательно для заполнения'),
    lastNameRu: string().required('Поле обязательно для заполнения'),
    photo: string(),
    priorId: number().required('Поле обязательно для заполнения'),
    surnameKz: string().required('Поле обязательно для заполнения'),
    surnameRu: string().required('Поле обязательно для заполнения'),
  });

export function useEmployeeUpdate() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<IEmployeeUpdateResponse>,
    AxiosError,
    IEmployeeUpdateRequest
  >(({ ...payload }) => api.put(`/admin/api/employees`, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.employees]),
        queryClient.invalidateQueries([queryKeys.employeeCount]);
    },
  });
}
