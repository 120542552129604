import { AxiosError } from 'axios';

import { IEnumFileTypesKeys } from '@consts/enums/IEnumFileTypes';
import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IFile } from '@/types/IFile';

type IFilesResponse = IPageAbleResponse<IFile>;

export function useFiles({
  ...params
}: IPageAbleRequest & {
  fileCategory?: IEnumFileTypesKeys;
  fileSubCategory?: string | null;
  searchQuery?: string;
}) {
  return useQuery<IFilesResponse, AxiosError>(
    [queryKeys.files, params],
    () =>
      api
        .get(`/admin/api/files`, {
          params,
        })
        .then((res) => res.data)
    // {
    //   enabled:
    //     !!localStorage.getItem(storageKeys.tokenAccess),
    // }
  );
}
