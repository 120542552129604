import { AxiosError, AxiosResponse } from 'axios';
import { mixed, number, object, ObjectSchema, string } from 'yup';

import { IEnumEmployeeTypesKeys } from '@consts/enums/IEnumEmployeeTypes';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IEmployee } from '@/types/IEmployee';

export type IEmployeeCreateRequest = Pick<
  IEmployee,
  | 'photo'
  | 'lastNameKz'
  | 'lastNameRu'
  | 'firstNameKz'
  | 'firstNameRu'
  | 'jobTitleKz'
  | 'jobTitleRu'
  | 'additionalInfoKz'
  | 'additionalInfoRu'
  | 'category'
  | 'surnameKz'
  | 'surnameRu'
  | 'priorId'
> & {
  file: any;
};

type IEmployeeCreateResponse = AxiosResponse<IEmployee>;

export const employeeCreateInitial: IEmployeeCreateRequest = {
  additionalInfoKz: '',
  additionalInfoRu: '',
  category: '',
  file: null,
  firstNameKz: '',
  firstNameRu: '',
  jobTitleKz: '',
  jobTitleRu: '',
  lastNameKz: '',
  lastNameRu: '',
  photo: '',
  priorId: 0,
  surnameKz: '',
  surnameRu: '',
};

export const employeeCreateValidation: ObjectSchema<IEmployeeCreateRequest> =
  object({
    additionalInfoKz: string(),
    additionalInfoRu: string(),
    category: string<IEnumEmployeeTypesKeys>().required(
      'Поле обязательно для заполнения'
    ),
    file: mixed().required('Поле обязательно для заполнения'),
    firstNameKz: string().required('Поле обязательно для заполнения'),
    firstNameRu: string().required('Поле обязательно для заполнения'),
    jobTitleKz: string().required('Поле обязательно для заполнения'),
    jobTitleRu: string().required('Поле обязательно для заполнения'),
    lastNameKz: string().required('Поле обязательно для заполнения'),
    lastNameRu: string().required('Поле обязательно для заполнения'),
    photo: string(),
    priorId: number().required('Поле обязательно для заполнения'),
    surnameKz: string().required('Поле обязательно для заполнения'),
    surnameRu: string().required('Поле обязательно для заполнения'),
  });

export function useEmployeeCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IEmployeeCreateResponse,
    AxiosError,
    IEmployeeCreateRequest
  >((payload) => api.post('/admin/api/employees', payload), {
    onError: () => {
      notify('error', 'Что-то пошло не так, повторите попытку позже.');
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.employees]),
        queryClient.invalidateQueries([queryKeys.employeeCount]);
    },
  });
}
