import { Route, Routes } from 'react-router-dom';

import StatementDetailsPage from './pages/StatementDetailsPage';
import StatementsPage from './pages/StatementsPage';

export const StatementsRouter = () => {
  return (
    <Routes>
      <Route element={<StatementsPage />} index />
      <Route element={<StatementDetailsPage />} path="/:id" />
    </Routes>
  );
};
