import { useNavigate } from 'react-router-dom';

import { storageKeys } from '@consts/storageKeys';
import { useQueryClient } from '@tanstack/react-query';

export function useLogout() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  async function logout() {
    localStorage.removeItem(storageKeys.tokenAccess);
    localStorage.removeItem(storageKeys.tokenRefresh);

    await queryClient.resetQueries({
      predicate: () => true,
    });
    navigate('/');
  }

  return { logout };
}
