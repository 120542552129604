import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import imageCompression from 'browser-image-compression';

import {
  employeeCreateInitial,
  employeeCreateValidation,
  useEmployeeCreate,
} from '@api/employees/useEmployeeCreate';
import { useMutateFileUploadLink } from '@api/files/useMutateFileUploadLink';
import { getEmployeeType } from '@consts/enums/IEnumEmployeeTypes';
import { EmployeesFormProvider, useEmployeesForm } from '@context/FormContext';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  Button,
  Container,
  FileInput,
  NumberInput,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { yupResolver } from '@mantine/form';
import { IconPhoto } from '@tabler/icons-react';
import { notify } from '@utils/notify';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import EmployeesTextEditor from '../components/molecules/EmployeesTextEditor';
import { CancelCreateModal } from '../components/organisms/CancelCreateModal';
import { employeesFilters } from '../consts/filters/employeesFilters';
import {
  employeePageActions,
  IEmployeePageActions,
} from '../consts/pageActions/IEmployeePageActions';

const EmployeeCreatePage: FC = () => {
  const employeeCreate = useEmployeeCreate();
  const uploadLink = useMutateFileUploadLink({ directory: 'employees' });
  const navigate = useNavigate();

  const [fileFormData, setFileFormData] = useState<FormData>();

  const form = useEmployeesForm({
    initialValues: employeeCreateInitial,
    validate: yupResolver(employeeCreateValidation),
  });

  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IEmployeePageActions
  >(employeesFilters, employeePageActions);

  useEffect(() => {
    if (form.values.file) {
      if (form.values.file.size <= 5000000) {
        imageCompression(form.values.file, {
          alwaysKeepResolution: true,
          maxSizeMB: 0.2,
          maxWidthOrHeight: 300,
          useWebWorker: true,
        }).then((compressedFile) => {
          const formData = new FormData();
          formData.append(
            'file',
            compressedFile as File,
            `${form.values.file.name}`
          );
          setFileFormData(formData);
        });
      } else if (form.values.file.size > 5000000) {
        form.setErrors({ file: 'Файл не должен превышать 5МВ' });
      }
    }
  }, [form.values.file]);

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/employees', title: 'Руководство' },
          { link: '/employees/create', title: 'Добавить сотрудника' },
        ]}
        openMenuKeys={['employees']}
        selectedMenuKeys={['employees']}
        title="Добавить струдника"
      />
      <EmployeesFormProvider form={form}>
        <Container className="my-10">
          <form
            onSubmit={form.onSubmit((values) => {
              if (fileFormData) {
                uploadLink
                  .mutateAsync({
                    file: fileFormData,
                    fileName: values.file.name,
                  })
                  .then((response) => {
                    return employeeCreate
                      .mutateAsync({
                        ...values,
                        photo: `${response.data.url}`,
                      })
                      .then(() => {
                        notify('success', 'Сотрудник добавлен');
                        navigate('/employees');
                      })
                      .catch(() => {
                        notify(
                          'error',
                          'Что-то пошло не так, повторите попытку позже.'
                        );
                      });
                  })
                  .catch(() => {
                    notify(
                      'error',
                      'Что-то пошло не так, повторите попытку позже.'
                    );
                  });
              }
            })}
          >
            <div>
              <Text fw={700} mb={20} size="lg">
                Добавить сотрудника
              </Text>
              <div className="grid grid-cols-2 items-baseline gap-2">
                <FileInput
                  accept="image/png,image/jpeg"
                  clearable
                  description={
                    <span className="text-gray-7">
                      Допустимые форматы загрузки файлов: jpg, jpeg, png. Размер
                      загружаемого файл не должен превышать 5 MB.
                    </span>
                  }
                  label="Фотография сотрудника"
                  leftSection={<IconPhoto stroke="1.2" />}
                  withAsterisk
                  {...form.getInputProps('file')}
                />
                <Select
                  data={getEmployeeType().map((type) => ({
                    label: type.label,
                    value: type.value,
                  }))}
                  label="Категория"
                  withAsterisk
                  {...form.getInputProps('category')}
                />
              </div>
            </div>

            <div className="border-1 mt-6 rounded-xl border bg-white p-6">
              <Text className="mb-4">Версия на казахском языке</Text>
              <div className="grid grid-cols-2 gap-6">
                <TextInput
                  label="Имя"
                  name="firstNameKz"
                  withAsterisk
                  {...form.getInputProps('firstNameKz')}
                />
                <TextInput
                  label="Фамилия"
                  name="lastNameKz"
                  withAsterisk
                  {...form.getInputProps('lastNameKz')}
                />
                <TextInput
                  label="Отчество"
                  name="surnameKz"
                  withAsterisk
                  {...form.getInputProps('surnameKz')}
                />
                <NumberInput
                  label="Prior ID"
                  name="priorId"
                  withAsterisk
                  {...form.getInputProps('priorId')}
                />
              </div>

              <TextInput
                className="mt-4"
                label="Должность"
                name="jobTitleKz"
                withAsterisk
                {...form.getInputProps('jobTitleKz')}
              />
              <div className="mt-4">
                <Text color="#868E96" mb={8} size="sm">
                  Подробнее о сотруднике
                </Text>
                <EmployeesTextEditor
                  lang="kz"
                  placeholder="Информация о сотруднике..."
                  type="additionalInfoKz"
                />
              </div>
            </div>

            <div className="border-1 mt-10 rounded-xl border bg-white p-6">
              <Text>Версия на русском языке</Text>
              <div className="grid grid-cols-2 gap-6">
                <TextInput
                  label="Имя"
                  name="firstNameRu"
                  withAsterisk
                  {...form.getInputProps('firstNameRu')}
                />
                <TextInput
                  label="Фамилия"
                  name="lastNameRu"
                  withAsterisk
                  {...form.getInputProps('lastNameRu')}
                />
                <TextInput
                  label="Отчество"
                  name="surnameRu"
                  withAsterisk
                  {...form.getInputProps('surnameRu')}
                />
              </div>

              <TextInput
                className="mt-4"
                label="Должность"
                name="title"
                withAsterisk
                {...form.getInputProps('jobTitleRu')}
              />
              <div className="mt-4">
                <Text color="#868E96" mb={8} size="sm">
                  Подробнее о сотруднике
                </Text>
                <EmployeesTextEditor
                  lang="ru"
                  placeholder="Информация о сотруднике..."
                  type="additionalInfoRu"
                />
              </div>
            </div>

            <div className="flex flex-row justify-end">
              <Button
                className="mt-4"
                onClick={() => {
                  setPartialActioned({
                    action: 'cancelCreate',
                  });
                }}
                variant="outline"
              >
                Отменить
              </Button>
              <Button
                className="ml-4 mt-4"
                disabled={!form.isDirty()}
                loading={employeeCreate.isLoading}
                type="submit"
                variant="outlined"
              >
                Опубликовать
              </Button>
            </div>
          </form>

          <CancelCreateModal
            isOpen={actioned.action === 'cancelCreate'}
            onClose={clearParams}
          />
        </Container>
      </EmployeesFormProvider>
    </div>
  );
};

export default EmployeeCreatePage;
