import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { useLogout } from '@api/auth/useLogout';
import ProfileAvatar from '@components/atoms/ProfileAvatar';
import { SidebarNavLink } from '@components/molecules/SidebarNavLink';
import { sidebars } from '@consts/sidebars';
import { PageMetaContext, usePageMetaContext } from '@context/PageMetaContext';
import { UserContext } from '@context/UserContext';
import {
  Anchor,
  AppShell,
  Breadcrumbs,
  Burger,
  Button,
  Group,
  Switch,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import AuthRoute from '@routes/AuthRoute';
import { IconLogout2 } from '@tabler/icons-react';

const AdminLayout: FC = () => {
  const {
    i18n: { changeLanguage, language },
    t,
  } = useTranslation();
  const { logout } = useLogout();
  const { userMe } = useContext(UserContext);

  const [locale, setLocale] = useState(language == 'kz');
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

  const pageMetaContext = useContext(PageMetaContext);
  const { openMenuKeys, selectedMenuKeys } = usePageMetaContext();

  const items = pageMetaContext.breadcrumbs?.map((item, index) => (
    <Anchor href={item.link} key={index}>
      {item.title}
    </Anchor>
  ));

  useEffect(() => {
    if (locale) {
      localStorage.setItem('language', 'kz');
      changeLanguage('kz');
    } else {
      localStorage.setItem('language', 'ru');
      changeLanguage('ru');
    }
  }, [locale]);

  return (
    <AuthRoute auth>
      <AppShell
        header={{ height: 60 }}
        navbar={{
          breakpoint: 'sm',
          collapsed: { desktop: !desktopOpened, mobile: !mobileOpened },
          width: 240,
        }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%" px="md">
            <Burger
              hiddenFrom="sm"
              onClick={toggleMobile}
              opened={mobileOpened}
              size="sm"
            />
            <Burger
              onClick={toggleDesktop}
              opened={desktopOpened}
              size="sm"
              visibleFrom="sm"
            />
            BCC Life
            <div className="ml-auto hidden items-center gap-2 lg:flex">
              <Switch
                checked={locale}
                className="mr-5"
                offLabel="Рус."
                onChange={(event) => {
                  setLocale(event.currentTarget.checked);
                }}
                onLabel="Каз."
                size="lg"
              />
              <ProfileAvatar />
            </div>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar className="justify-between border-none" p="md">
          <div>
            {sidebars()
              .filter((item) =>
                item.permissions.includes(userMe?.role.toLowerCase() ?? '')
              )
              .map((item) => (
                <SidebarNavLink
                  item={item}
                  key={item.key}
                  opened={!desktopOpened}
                  openMenuKeys={openMenuKeys}
                  selectedMenuKeys={selectedMenuKeys}
                />
              ))}
          </div>
          <AppShell.Section>
            <Button
              leftSection={<IconLogout2 stroke={1.2} />}
              onClick={() => logout()}
              variant="white"
              w="100%"
            >
              Выход
            </Button>
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main bg="#F5F8FA">
          <Breadcrumbs
            className="breadcrumbs"
            separator="/"
            separatorMargin="xs"
          >
            {items}
          </Breadcrumbs>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </AuthRoute>
  );
};

export default AdminLayout;
