import { AxiosError } from 'axios';

import { ContractsStatusEnumKeys } from '@consts/enums/IEnumContractsStatus';
import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IContract } from '@/types/IContract';

type IContractsResponse = IPageAbleResponse<IContract>;

export function useContracts({
  ...params
}: IPageAbleRequest & {
  status?: ContractsStatusEnumKeys;
  type?: 'INDIVIDUAL' | 'COMPANY';
  userId?: number;
}) {
  return useQuery<IContractsResponse, AxiosError>(
    [queryKeys.contracts, params],
    () =>
      api
        .get(`/contract-service/api/contracts/get-all-contracts`, { params })
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) && !!params.userId,
    }
  );
}
