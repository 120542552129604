export type ILocalization = 'ru' | 'en';

export enum IEnumLocalization {
  en = 'Английский',
  ru = 'Русский',
}

export enum IEnumLocalizationEng {
  en = 'English',
  ru = 'Russian',
}

export enum IEnumLocalizationGenitive {
  en = 'английском',
  ru = 'русском',
}
