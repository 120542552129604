import { useNavigate } from 'react-router-dom';

import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { storageKeys } from '@consts/storageKeys';
import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IBackendError } from '@/types/common/IBackendError';
import { IAuth } from '@/types/IAuth';

export type IUserAuthRequest = {
  password: string;
  username: string;
};

export const authInitial: IUserAuthRequest = {
  password: '',
  username: '',
};

export const authValidation: ObjectSchema<IUserAuthRequest> = object().shape({
  password: string()
    .required()
    .max(30, 'Пароль должен быть не более 20 символов'),
  username: string().required(),
});

export function useAuth() {
  const navigate = useNavigate();

  return useMutation<
    AxiosResponse<IAuth>,
    AxiosError<Maybe<IBackendError>>,
    IUserAuthRequest
  >((payload) => api.post('/user-service/api/auth/login', payload), {
    onSuccess: (response) => {
      if (response.data.access_token) {
        localStorage.setItem(
          storageKeys.tokenAccess,
          response.data.access_token
        );
        localStorage.setItem(
          storageKeys.tokenRefresh,
          response.data.refresh_token
        );
        navigate('/');
      }
    },
  });
}
