import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '@mantine/core';

type CancelCreateModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CancelCreateModal: FC<CancelCreateModalProps> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Отменить"
    >
      <div className="m-6 mx-auto mt-0 max-w-[410px] text-center font-semibold text-gray-600">
        {`Внесенные изменения не будут сохранены. Продолжить?`}
      </div>

      <div className="flex justify-end gap-4 pt-0">
        <Button className="!w-[150px]" onClick={onClose} variant="outline">
          Нет
        </Button>
        <Button className="!w-[150px]" onClick={() => navigate('/news')}>
          Да
        </Button>
      </div>
    </Modal>
  );
};
