import { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import { useFiles } from '@api/files/useFiles';
import { useFilesCount } from '@api/files/useFilesCount';
import { Search } from '@components/atoms/Search';
import { QueryTable } from '@components/organisms/QueryTable';
import {
  getCorporateFileType,
  IEnumFileTypesKeys,
} from '@consts/enums/IEnumFileTypes';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, Chip, Group, Select, Text } from '@mantine/core';
import { YearPickerInput } from '@mantine/dates';
import {
  IconCalendar,
  IconEdit,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { ConfirmDeleteModal } from '../components/organisms/ConfirmDeleteModal';
import { FileUpdateModal } from '../components/organisms/FileUpdateModal';
import { filesColumns } from '../consts/columns/filesColumns';
import { filesFilters } from '../consts/filters/filesFilters';
import {
  filesPageActions,
  IFilesPageActions,
} from '../consts/pageActions/IFilesPageActions';

const FilesPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const [subCategory, setSubCategory] = useState<string | null>(null);
  const [subcategoryYear, setSubcategoryYear] = useState<string | null>(null);

  const [value, setValue] = useState<IEnumFileTypesKeys>(
    'FINANCIAL_STATEMENTS'
  );

  const { actioned, clearParams, filtered, onFilter, setPartialActioned } =
    useUrlFilteredActioned<IPageAbleRequest, IFilesPageActions>(
      filesFilters,
      filesPageActions
    );

  const handleTabChange = (value: string) => {
    setValue(value as IEnumFileTypesKeys);
  };

  const filesQuery = useFiles({
    ...filtered,
    fileCategory: value,
    fileSubCategory: subCategory || subcategoryYear || undefined,
    searchQuery,
  });

  const filesCountQuery = useFilesCount();
  const filesCount = filesCountQuery.data?.data;
  const totalFilesCount = useMemo(() => {
    if (filesCount) {
      return (
        filesCount?.financialStatementCount +
        filesCount?.corporateDocumentsCount +
        filesCount?.remunerationInsuranceAgentsCount +
        filesCount?.registerOfInsuranceAgentsCount +
        filesCount?.informationAboutParticipationAssociationsUnions
      );
    }
    return 0;
  }, [filesCount]);

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/files', title: 'Файлы' }]}
        openMenuKeys={['files']}
        selectedMenuKeys={['files']}
        title="Файлы"
      />
      <div className="flex pt-6">
        <Text fw={600} mr={12} size="lg">
          Все файлы
        </Text>
        <span className="rounded-2xl bg-[#EBF3FD] px-2 text-[#1F4A62]">
          {totalFilesCount}
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="flex flex-col gap-4">
            <div className="col-span-3 mt-5 flex items-baseline justify-between gap-4">
              <Chip.Group
                multiple={false}
                onChange={(value) => handleTabChange(value)}
                value={value}
              >
                <Group className="gap-0" justify="start">
                  <Chip value="FINANCIAL_STATEMENTS" variant="outline">
                    Финансовая отчетность
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {filesCount?.financialStatementCount}
                    </span>
                  </Chip>
                  <Chip value="CORPORATE_DOCUMENTS" variant="outline">
                    Корпоративные документы
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {filesCount?.corporateDocumentsCount}
                    </span>
                  </Chip>
                  <Chip value="REMUNERATION_INSURANCE_AGENTS" variant="outline">
                    Вознаграждения страховых агентов
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {filesCount?.remunerationInsuranceAgentsCount}
                    </span>
                  </Chip>
                  <Chip value="REGISTER_OF_INSURANCE_AGENTS" variant="outline">
                    Реестр страховых агентов
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {filesCount?.registerOfInsuranceAgentsCount}
                    </span>
                  </Chip>
                  <Chip
                    value="INFORMATION_ABOUT_PARTICIPATION_ASSOCIATIONS_UNIONS"
                    variant="outline"
                  >
                    Сведения об участии в ассоциациях и союзах
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {
                        filesCount?.informationAboutParticipationAssociationsUnions
                      }
                    </span>
                  </Chip>
                </Group>
              </Chip.Group>
            </div>
            <div className="flex items-baseline justify-between gap-4">
              {value == 'FINANCIAL_STATEMENTS' ? (
                <YearPickerInput
                  className="col-span-1 w-[200px]"
                  clearable
                  leftSection={<IconCalendar />}
                  onChange={(value) =>
                    setSubcategoryYear(
                      value ? moment(value).year().toString() : null
                    )
                  }
                  placeholder="Подкатегория"
                  value={
                    subcategoryYear
                      ? moment(subcategoryYear, 'YYYY').toDate()
                      : null
                  }
                  valueFormat="YYYY"
                />
              ) : value == 'CORPORATE_DOCUMENTS' ? (
                <Select
                  clearable
                  data={getCorporateFileType().map((type) => ({
                    label: type.label,
                    value: type.value,
                  }))}
                  onChange={setSubCategory}
                  placeholder="Подкатегория"
                  value={subCategory}
                  withAsterisk
                />
              ) : (
                <div></div>
              )}
              <div className="flex items-baseline gap-4">
                <Search onChange={setSearchQuery} searchQuery={searchQuery} />
                <Link to="/files/upload">
                  <Button leftSection={<IconPlus />}>Загрузить</Button>
                </Link>
              </div>
            </div>
            <div className="overflow-x-scroll rounded-xl bg-white p-5">
              <QueryTable
                actions={(row) => [
                  {
                    icon: <IconTrash />,
                    onClick: () => {
                      setPartialActioned({
                        action: 'delete',
                        actionId: String(row.id) ?? '',
                      });
                    },
                    title: 'Удалить',
                  },
                  {
                    icon: <IconEdit />,
                    onClick: () => {
                      setPartialActioned({
                        action: 'edit',
                        actionFileNameKz: String(row.fileNameKz) ?? '',
                        actionFileNameRu: String(row.fileNameRu) ?? '',
                        actionId: String(row.id) ?? '',
                      });
                    },
                    title: 'Редактировать наименование',
                  },
                ]}
                columns={filesColumns}
                data={filesQuery.data}
                empty={{
                  description: 'В данном разделе отсутствуют загруженные файлы',
                  title: '',
                }}
                query={filesQuery}
                tableFilter={{
                  filtered,
                  filters: filesFilters,
                  onFilter,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <ConfirmDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />

      <FileUpdateModal
        fileNameKz={actioned.actionFileNameKz}
        fileNameRu={actioned.actionFileNameRu}
        id={actioned.actionId}
        isOpen={actioned.action === 'edit'}
        onClose={clearParams}
      />
    </div>
  );
};

export default FilesPage;
