
import {
    defaultVariantColorsResolver,
    VariantColorsResolver,
    parseThemeColor,
    rgba,
  } from '@mantine/core';

export const variantColorResolver: VariantColorsResolver = (input) => {
    const defaultResolvedColors = defaultVariantColorsResolver(input);
    const parsedColor = parseThemeColor({
      color: input.color || input.theme.primaryColor,
      theme: input.theme,
    });
  
    // Completely override variant
    if (input.variant === 'light') {
      return {
        background: rgba(parsedColor.value, 0.1),
        hover: rgba(parsedColor.value, 0.08),
        border: 'none',
        color: 'var(--mantine-color-white)',
      };
    }

    if (input.variant === 'subtle') {
        return {
          background: 'transparent',
          hover: rgba(parsedColor.value, 0.08),
          border: 'none',
          color: '#AEAEB2',
        };
      }
  
    return defaultResolvedColors;
  };