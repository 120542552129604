import { FC } from 'react';

import clsx from 'clsx';
import { t } from 'i18next';

import {
  authInitial,
  authValidation,
  IUserAuthRequest,
  useAuth,
} from '@api/auth/useAuth';
import { PageMeta } from '@context/PageMetaContext';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

const LoginPage: FC = () => {
  const login = useAuth();

  const form = useForm<IUserAuthRequest>({
    initialValues: authInitial,
    validate: yupResolver(authValidation),
  });

  return (
    <>
      <PageMeta title="Логин" />

      <div className="mb-8 flex flex-col items-center gap-5">
        <span
          className={clsx(
            'truncate whitespace-nowrap text-3xl font-bold tracking-wide'
          )}
        >
          <span className="text-primary">BCC Life</span>
        </span>
      </div>

      <form
        className="w-full max-w-sm lg:w-2/3"
        onSubmit={form.onSubmit(({ password, username }) => {
          login
            .mutateAsync({
              password: password.trim(),
              username: username.trim(),
            })
            .catch((error) => {
              if (
                error.response?.data.errorCode == 'USER_NOT_FOUND_BY_USERNAME'
              ) {
                form.setFieldError('username', 'Пользователь не найден');
              } else if (
                error.response?.data.errorCode == 'PASSWORD_INCORRECT'
              ) {
                form.setFieldError('password', 'Неверный пароль');
              }
            });
        })}
      >
        <TextInput
          className="w-full"
          label={t('auth.username')}
          name="username"
          placeholder="username"
          withAsterisk
          {...form.getInputProps('username')}
        />
        <PasswordInput
          className="mt-5 w-full"
          label="Пароль"
          name="password"
          placeholder="******"
          withAsterisk
          {...form.getInputProps('password')}
        />
        <div className="mt-8 flex flex-col">
          <Button
            className="bg-color-[#11122C]"
            loading={login.isLoading}
            type="submit"
          >
            Войти
          </Button>
        </div>
      </form>
    </>
  );
};

export default LoginPage;
