import { AxiosError } from 'axios';

import { ApplicationsStatusEnumKeys } from '@consts/enums/IEnumApplicationsStatus';
import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IStatement } from '@/types/IStatement';

type IStatementsResponse = IPageAbleResponse<IStatement>;

export function useStatements({
  ...params
}: IPageAbleRequest & {
  searchStatementName?: string;
  status: ApplicationsStatusEnumKeys | null;
  type?: 'COMPANY' | 'INDIVIDUAL';
  userId?: number;
}) {
  return useQuery<IStatementsResponse, AxiosError>(
    [queryKeys.statements, params],
    () =>
      api
        .get(`/contract-service/api/statements/get-all-statements`, {
          params,
        })
        .then((res) => res.data)
    // {
    //   enabled:
    //     !!localStorage.getItem(storageKeys.tokenAccess),
    // }
  );
}
