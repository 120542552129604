import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';
import { mixed, object, ObjectSchema, string } from 'yup';

import { IEnumFileTypesKeys } from '@consts/enums/IEnumFileTypes';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IEmployee } from '@/types/IEmployee';
import { IFile } from '@/types/IFile';

export type IFilesUploadRequest = Pick<
  IFile,
  | 'category'
  | 'subcategory'
  | 'fileKz'
  | 'fileNameKz'
  | 'fileRu'
  | 'fileNameRu'
  | 'lastModifiedDate'
> & {
  fileFieldKz: any;
  fileFieldRu: any;
};

type IFilesUploadResponse = AxiosResponse<IEmployee>;

export const filesUploadInitial: IFilesUploadRequest = {
  category: 'FINANCIAL_STATEMENTS',
  fileFieldKz: null,
  fileFieldRu: null,
  fileKz: '',
  fileNameKz: '',
  fileNameRu: '',
  fileRu: '',
  lastModifiedDate: moment(new Date()).format('yyyy-MM-DD HH:mm:ss'),
  subcategory: '',
};

export const filesUploadValidation: ObjectSchema<IFilesUploadRequest> = object({
  category: string<IEnumFileTypesKeys>().required(
    'Поле обязательно для заполнения'
  ),
  fileFieldKz: mixed().required('Поле обязательно для заполнения'),
  fileFieldRu: mixed().required('Поле обязательно для заполнения'),
  fileKz: string(),
  fileNameKz: string().required('Поле обязательно для заполнения'),
  fileNameRu: string().required('Поле обязательно для заполнения'),
  fileRu: string(),
  lastModifiedDate: string().required('Поле обязательно для заполнения'),
  subcategory: string().when('category', {
    is: (category: IEnumFileTypesKeys) =>
      ![
        'REMUNERATION_INSURANCE_AGENTS',
        'REGISTER_OF_INSURANCE_AGENTS',
        'INFORMATION_ABOUT_PARTICIPATION_ASSOCIATIONS_UNIONS',
      ].includes(category),
    otherwise: (s) => s.notRequired(),
    then: (s) => s.required('Поле обязательно для заполнения'),
  }),
});

export function useFilesUpload() {
  const queryClient = useQueryClient();

  return useMutation<IFilesUploadResponse, AxiosError, IFilesUploadRequest>(
    (payload) => api.post('/admin/api/files', payload),
    {
      onError: (error) => {
        if (error.message == 'FileResponseException') console.log(error);
        notify(
          'error',
          'Размер картинки не может быть свыше 5МВ, а файл свыше 10МВ.'
        );
      },
      onSuccess: () => queryClient.invalidateQueries([queryKeys.files]),
      // queryClient.invalidateQueries([queryKeys.filesCount]);
    }
  );
}
