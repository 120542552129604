import { AxiosError, AxiosResponse } from 'axios';
import { mixed, number, object, ObjectSchema, string } from 'yup';

import { IEnumPostTypesKeys } from '@consts/enums/IEnumPostTypes';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';

import { INewsPost } from '@/types/INewsPost';

export type INewsUpdateRequest = Pick<
  INewsPost,
  | 'postType'
  | 'createdAt'
  | 'contentKz'
  | 'contentRu'
  | 'titleKz'
  | 'titleRu'
  | 'imageKz'
  | 'imageRu'
  | 'id'
  | 'slugKz'
  | 'slugRu'
> & {
  fileKz: any;
  fileRu: any;
};

type INewsUpdateResponse = void;

export const newsUpdateInitial: INewsUpdateRequest = {
  contentKz: '',
  contentRu: '',
  createdAt: new Date(),
  fileKz: null,
  fileRu: null,
  id: 0,
  imageKz: '',
  imageRu: '',
  postType: 'NEWS',
  slugKz: '',
  slugRu: '',
  titleKz: '',
  titleRu: '',
};

export const newsUpdateValidation: ObjectSchema<INewsUpdateRequest> = object({
  contentKz: string(),
  contentRu: string(),
  createdAt: string().required('Поле обязательно для заполнения'),
  fileKz: mixed().nullable(),
  fileRu: mixed().nullable(),
  id: number().required('Поле обязательно для заполнения'),
  imageKz: string(),
  imageRu: string(),
  postType: string<IEnumPostTypesKeys>().required(
    'Поле обязательно для заполнения'
  ),
  slugKz: string().required('Поле обязательно для заполнения'),
  slugRu: string().required('Поле обязательно для заполнения'),
  titleKz: string().required('Поле обязательно для заполнения'),
  titleRu: string().required('Поле обязательно для заполнения'),
});

export function useNewsUpdate() {
  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<INewsUpdateResponse>,
    AxiosError,
    INewsUpdateRequest
  >(({ ...payload }) => api.put(`/admin/api/news/edit`, payload), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([queryKeys.newsPost]);
    },
  });
}
