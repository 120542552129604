import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type IFilesDeleteRequest = { id: number };

type IFilesDeleteResponse = AxiosResponse<void>;

export function useFilesDelete() {
  const queryClient = useQueryClient();

  return useMutation<IFilesDeleteResponse, AxiosError, IFilesDeleteRequest>(
    ({ id }) => api.delete(`/admin/api/files/${id}`),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.files]),
          queryClient.invalidateQueries([queryKeys.filesCount]);
      },
    }
  );
}
