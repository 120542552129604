import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IUser } from '@/types/IUser';

type IClientsResponse = IPageAbleResponse<IUser>;

export function useClients({
  ...params
}: IPageAbleRequest & {
  searchQuery?: string;
}) {
  return useQuery<IClientsResponse, AxiosError>(
    [queryKeys.clients, params],
    () =>
      api
        .get(`/user-service/api/users/get-users-by-role-user`, {
          params,
        })
        .then((res) => res.data)
  );
}
