import { IPageActions } from '@/types/common/IPageActions';

enum IEnumRequestPageActions {
  'change-status',
}

export type IRequestPageActions = {
  action: keyof typeof IEnumRequestPageActions;
  actionId: string;
  actionStatus: string;
};

export const requestsPageActions: IPageActions<IRequestPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumRequestPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionStatus: { default: '' },
};
