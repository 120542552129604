import { Route, Routes } from 'react-router-dom';

import ClientDetailsPage from './pages/ClientDetailsPage';
import ClientsPage from './pages/ClientsPage';

export const ClientsRouter = () => {
  return (
    <Routes>
      <Route element={<ClientsPage />} index />
      <Route element={<ClientDetailsPage />} path="/:id" />
    </Routes>
  );
};
