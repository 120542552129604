import {
  IconAddressBook,
  IconClipboardText,
  IconCloudUpload,
  IconNews,
  IconUser,
  IconUsers,
} from '@tabler/icons-react';

export interface SidebarInterface {
  children?: SidebarInterface[];
  icon?: any;
  key: string;
  link: string;
  permissions: string[];
  title?: string;
}

export const sidebars = (): SidebarInterface[] => {
  return [
    {
      icon: IconNews,
      key: 'news',
      link: 'news',
      permissions: ['admin'],
      title: 'Новости',
    },
    {
      icon: IconUsers,
      key: 'employees',
      link: 'employees',
      permissions: ['admin'],
      title: 'Руководство',
    },
    {
      icon: IconClipboardText,
      key: 'statements',
      link: 'statements',
      permissions: ['admin', 'manager'],
      title: 'Заявления',
    },
    {
      icon: IconAddressBook,
      key: 'requests',
      link: 'requests',
      permissions: ['admin', 'manager'],
      title: 'Заявки',
    },
    {
      icon: IconCloudUpload,
      key: 'files',
      link: 'files',
      permissions: ['admin'],
      title: 'Файлы',
    },
    {
      icon: IconUser,
      key: 'clients',
      link: 'clients',
      permissions: ['admin', 'manager'],
      title: 'Клиенты',
    },
  ];
};
