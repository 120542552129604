import { IPageActions } from '@/types/common/IPageActions';

enum IEnumFilesPageActions {
  'delete',
  'edit',
  'cancelCreate',
}

export type IFilesPageActions = {
  action: keyof typeof IEnumFilesPageActions;
  actionFileNameKz?: string;
  actionFileNameRu?: string;
  actionId: string;
};

export const filesPageActions: IPageActions<IFilesPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumFilesPageActions).includes(value),
    default: '',
  },
  actionFileNameKz: { default: '' },
  actionFileNameRu: { default: '' },
  actionId: { default: '' },
};
