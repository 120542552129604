import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import parse from 'html-react-parser';

import { useEmployee } from '@api/employees/useEmployee';
import { IEnumEmployeeType } from '@consts/enums/IEnumEmployeeTypes';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Badge, Button, Container, Image, Text } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { ConfirmDeleteModal } from '../components/organisms/ConfirmDeleteModal';
import { employeesFilters } from '../consts/filters/employeesFilters';
import {
  employeePageActions,
  IEmployeePageActions,
} from '../consts/pageActions/IEmployeePageActions';

const EmployeeDetailsPage: FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: string }>();

  const employeeQuery = useEmployee({ id: +id });
  const employee = employeeQuery.data?.data;

  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IEmployeePageActions
  >(employeesFilters, employeePageActions);

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/employees', title: 'Руководство' },
          {
            link: '/employees/details',
            title:
              language == 'kz'
                ? `${employee?.firstNameKz} ${employee?.lastNameKz} ${employee?.surnameKz}`
                : `${employee?.firstNameRu} ${employee?.lastNameRu} ${employee?.surnameRu}`,
          },
        ]}
        openMenuKeys={['employees']}
        selectedMenuKeys={['employees']}
        title={
          language == 'kz'
            ? `${employee?.firstNameKz} ${employee?.lastNameKz} ${employee?.surnameKz}`
            : `${employee?.firstNameRu} ${employee?.lastNameRu} ${employee?.surnameRu}`
        }
      />
      <Container className="mb-20">
        <div className="mb-5 flex w-full items-end justify-end">
          <Button
            leftSection={<IconEdit size={14} />}
            ml={20}
            onClick={() => navigate(`/employees/edit/${id}`)}
          >
            Редактировать
          </Button>
          <Button
            color="red"
            leftSection={<IconTrash size={14} />}
            ml={20}
            onClick={() => {
              setPartialActioned({
                action: 'delete',
                actionId: String(id) ?? '',
              });
            }}
          >
            Удалить
          </Button>
        </div>

        <div className="grid grid-cols-3 gap-5">
          <div className="col-span-1">
            <Image
              alt={
                language == 'kz'
                  ? `${employee?.firstNameKz} ${employee?.lastNameKz} ${employee?.surnameKz}`
                  : `${employee?.firstNameRu} ${employee?.lastNameRu} ${employee?.surnameRu}`
              }
              fallbackSrc="https://placehold.co/600x400/F1F4F7/1A2434?text=Не удалось загрузить фото"
              h={300}
              mb={20}
              radius="md"
              src={employee?.photo}
            />
            <Badge color="cyan">
              {IEnumEmployeeType[employee?.category || '']}
            </Badge>
            <Text fw={600} my={10}>
              {language == 'kz'
                ? `${employee?.firstNameKz} ${employee?.lastNameKz} ${employee?.surnameKz}`
                : `${employee?.firstNameRu} ${employee?.lastNameRu} ${employee?.surnameRu}`}
            </Text>
            <Text c="dimmed">
              {language == 'kz' ? employee?.jobTitleKz : employee?.jobTitleRu}
            </Text>
          </div>

          <div className="editor-content col-span-2">
            {parse(
              (language == 'kz'
                ? employee?.additionalInfoKz
                : employee?.additionalInfoRu) ?? ''
            )}
          </div>
        </div>
      </Container>

      <ConfirmDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />
    </div>
  );
};

export default EmployeeDetailsPage;
