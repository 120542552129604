import { Route, Routes } from 'react-router-dom';

import RequestsPage from './pages/RequestsPage';

export const RequestsRouter = () => {
  return (
    <Routes>
      <Route element={<RequestsPage />} index />
    </Routes>
  );
};
