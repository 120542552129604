import { FC, MemoExoticComponent, SVGProps } from 'react';

import { Title } from '@mantine/core';
import { IconListSearch } from '@tabler/icons-react';

export const NotFoundResult: FC<{
  Icon?: MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => JSX.Element>;
  subTitle?: string;
  title?: string;
}> = ({
  subTitle = 'Попробуйте перезагрузить страницу',
  title = 'Страница не найдена',
}) => {
  return (
    <div className="flex h-fit flex-col items-center justify-center gap-4 bg-white px-2 py-8 text-center">
      <IconListSearch color="#9CA3AF" size={80} stroke={0.6} />
      <Title className="mt-4 text-base lg:text-2xl" order={2}>
        {title}
      </Title>
      <span className="font-medium text-gray-400">{subTitle}</span>
    </div>
  );
};
