import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { initActioned, initFiltered, toUrlParams } from '@utils/filtered';

import { IFilters, IFiltersPropsDefault } from '@/types/common/IFilters';
import { IPageActions } from '@/types/common/IPageActions';

export function useUrlFilteredActioned<
  FilterProps extends IFiltersPropsDefault | undefined = IFiltersPropsDefault,
  ActionProps extends object | undefined = object,
>(filters?: IFilters, actions?: IPageActions) {
  const [search, setSearch] = useSearchParams();

  const [filtered, setFiltered] = useState(initFiltered<FilterProps>(filters));
  const [actioned, setActioned] = useState(initActioned<ActionProps>(actions));
  const setPartialActioned = (keys: Partial<ActionProps>) => {
    setActioned((prev) => ({ ...prev, ...keys }));
  };

  const onFilter = (keys: Partial<FilterProps>) => {
    setFiltered((prev) => ({ ...prev, ...keys }));
  };

  const clearParams = () => {
    setActioned({});
  };

  useEffect(() => {
    const urlParams = toUrlParams({
      actioned,
      actions,
      filtered,
      filters,
    });

    if (urlParams != search.toString()) {
      setSearch(urlParams);
    }
  }, [filtered, actioned]);

  useEffect(() => {
    const urlParams = toUrlParams({
      actioned,
      actions,
      filtered,
      filters,
    });

    if (
      urlParams != decodeURIComponent(search.toString().replace(/\+/g, ' '))
    ) {
      setFiltered(initFiltered<FilterProps>(filters));
      setActioned(initActioned<ActionProps>(actions));
    }
  }, [search]);

  return {
    actioned,
    clearParams,
    filtered,
    onFilter,
    setActioned,
    setFiltered,
    setPartialActioned,
  };
}
