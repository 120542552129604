import { type FC, useEffect } from 'react';

import {
  statementCreateInitial,
  statementCreateValidation,
  useStatementCreate,
} from '@api/statements/useStatementCreate';
import {
  getStatementLifeReasonType,
  getStatementOSNSReasonType,
} from '@consts/enums/IEnumStatementReasonTypes';
import { Button, Modal, Select, Text, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';

type CreateStatementModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  premiumInsurance?: string;
  sumInsured?: string;
  type?: 'INDIVIDUAL' | 'COMPANY';
};

export const CreateStatementModal: FC<CreateStatementModalProps> = ({
  id = '',
  isOpen,
  onClose,
  premiumInsurance = '',
  sumInsured = '',
  type = 'INDIVIDUAL',
}) => {
  const form = useForm({
    initialValues: {
      ...statementCreateInitial,
    },
    validate: yupResolver(statementCreateValidation),
  });

  const createStatement = useStatementCreate();

  useEffect(() => {
    form.setFieldValue('premiumInsurance', premiumInsurance);
    form.setFieldValue('contractNumber', id);
    form.setFieldValue('sumInsured', sumInsured);
  }, [premiumInsurance, id, sumInsured]);

  const handleClose = () => {
    form.setValues({ ...statementCreateInitial });
    onClose();
  };

  return (
    <Modal
      centered
      onClose={handleClose}
      opened={isOpen}
      size={776}
      title={`Создать заявление по договору # ${id}`}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          createStatement
            .mutateAsync({
              ...values,
            })
            .then(() => {
              notify('success', 'Наименование файлов успешно изменены!');
              form.setValues({ ...statementCreateInitial });
              onClose();
            });
        })}
      >
        <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
          <div className="grid items-baseline ">
            <Text color="#6D758F" fw={400} size="sm">
              Тип договора:
            </Text>
            <Text color="#6D758F" fw={600} size="sm">
              {type == 'INDIVIDUAL'
                ? 'Страхование жизни'
                : 'Страхование работников'}
            </Text>
          </div>

          <div className="grid items-baseline ">
            <Text color="#6D758F" fw={400} size="sm">
              Номер договора:
            </Text>
            <Text color="#6D758F" fw={600} size="sm">
              {id || ''}
            </Text>
          </div>

          <div>
            <Text color="#6D758F" fw={400} size="sm">
              Страховая сумма:
            </Text>
            <Text color="#6D758F" fw={600} size="sm">
              {sumInsured || '-'}
            </Text>
          </div>

          <div>
            <Text color="#6D758F" fw={400} size="sm">
              Страховая премия:
            </Text>
            <Text color="#464C5D" fw={500} size="md">
              {premiumInsurance || '-'}
            </Text>
          </div>
        </div>
        <div className="mb-5 grid grid-cols-2 items-baseline gap-4">
          <TextInput
            className="mt-4"
            label="ФИО заявителя"
            name="acquirer"
            withAsterisk
            {...form.getInputProps('acquirer')}
          />
          <TextInput
            className="mt-4"
            label="ФИО выгодобретателя"
            name="beneficiary"
            withAsterisk
            {...form.getInputProps('beneficiary')}
          />
        </div>
        {type == 'INDIVIDUAL' && (
          <Select
            clearable
            comboboxProps={{ withinPortal: false }}
            data={getStatementLifeReasonType().map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            label="Тип несчастного случая"
            withAsterisk
            {...form.getInputProps('reason')}
          />
        )}
        {type == 'COMPANY' && (
          <Select
            data={getStatementOSNSReasonType().map((type) => ({
              label: type.label,
              value: type.value,
            }))}
            label="Тип несчастного случая"
            withAsterisk
            {...form.getInputProps('reason')}
          />
        )}

        <div className="justify-end-0 mt-5 flex flex-row">
          <div className="flex justify-end gap-4 pt-0">
            <Button
              className="!w-[150px]"
              onClick={handleClose}
              variant="subtle"
            >
              Отменить
            </Button>
            <Button
              className="!w-[150px]"
              disabled={!form.isDirty()}
              loading={createStatement.isLoading}
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
