import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import parse from 'html-react-parser';
import moment from 'moment';

import { useNewsPost } from '@api/news/useNewsPost';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, Container, Image, Skeleton, Text } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { ConfirmDeleteModal } from '../components/organisms/ConfirmDeleteModal';
import { newsFilters } from '../consts/filters/newsFilters';
import {
  INewsPageActions,
  newsPageActions,
} from '../consts/pageActions/INewsPageActions';

const NewsDetailsPage: FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const newsPostQuery = useNewsPost({ id: +id });
  const newsPost = newsPostQuery.data?.data;

  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    INewsPageActions
  >(newsFilters, newsPageActions);

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/news', title: 'Новости' },
          {
            link: '/news/details',
            title:
              language == 'kz'
                ? `${newsPost?.titleKz}`
                : `${newsPost?.titleRu}`,
          },
        ]}
        openMenuKeys={['news']}
        selectedMenuKeys={['news']}
        title="Новости"
      />
      {newsPostQuery.isLoading ? (
        <>
          <Skeleton className="h-56 rounded-xl" />
          <Skeleton className="h-56 rounded-xl" />
        </>
      ) : !newsPost ? (
        <NotFoundResult subTitle="" title="Ничего не найдено" />
      ) : (
        <Container className="my-10">
          <div className="mb-10 flex items-center justify-end">
            <Button
              color="red"
              ml={20}
              onClick={() => {
                setPartialActioned({
                  action: 'delete',
                  actionId: id ?? '',
                });
              }}
            >
              Удалить
            </Button>
            <Button ml={20} onClick={() => navigate(`/news/edit/${id}`)}>
              Редактировать
            </Button>
          </div>
          <Text c="dimmed">
            {moment(newsPost.createdAt).format('DD.MM.YYYY')}
          </Text>
          <Text fw={600} size="xl">
            {language == 'kz' ? newsPost.titleKz : newsPost.titleRu}
          </Text>
          <Image
            alt={language == 'kz' ? newsPost.titleKz : newsPost.titleRu}
            fallbackSrc="https://placehold.co/600x400/F1F4F7/1A2434?text=Не удалось загрузить фото"
            h={300}
            mb={20}
            mt={10}
            radius="md"
            src={language == 'kz' ? newsPost.imageKz : newsPost.imageRu}
          />
          <div className="editor-content">
            {parse(
              (language == 'kz' ? newsPost.contentKz : newsPost.contentRu) ?? ''
            )}
          </div>
        </Container>
      )}

      <ConfirmDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />
    </div>
  );
};

export default NewsDetailsPage;
