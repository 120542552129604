import { FC } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import AuthRoute from '@routes/AuthRoute';

const AuthLayout: FC = () => {
  const location = useLocation();

  return (
    <AuthRoute auth={false}>
      <div className="grid h-full w-full">
        <div className="flex h-full flex-col gap-8 overflow-auto p-8 md:gap-16 md:p-16">
          <div className="flex w-full flex-grow flex-col items-center justify-center bg-white">
            <Outlet />
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

export default AuthLayout;
