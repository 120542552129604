import { IPageActions } from '@/types/common/IPageActions';

enum IEnumNewsPageActions {
  'delete',
  'cancelCreate',
}

export type INewsPageActions = {
  action: keyof typeof IEnumNewsPageActions;
  actionId: string;
};

export const newsPageActions: IPageActions<INewsPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumNewsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
