import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import moment from 'moment';

import { useStatement } from '@api/statements/useStatement';
import { useStatementAmount } from '@api/statements/useStatementAmount';
import { useStatementChangeStatus } from '@api/statements/useStatementChangeStatus.ts';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { ApplicationDocumentsStatusEnumKeys } from '@consts/enums/IEnumApplicationDocumentsStatus';
import {
  ApplicationsStatusEnumKeys,
  getApplicationsStatuses,
} from '@consts/enums/IEnumApplicationsStatus';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  ActionIcon,
  Alert,
  Button,
  NumberInput,
  Select,
  Skeleton,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconCheck,
  IconEdit,
  IconInfoCircle,
  IconX,
} from '@tabler/icons-react';
import { notify } from '@utils/notify';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { ApplicationStatusTag } from '../components/atoms/ApplicationStatusTag';
import { ChangeStatementStatusModal } from '../components/organisms/ChangeStatementStatusModal';
import { ChangeStatusModal } from '../components/organisms/ChangeStatusModal';
import { statementsFilters } from '../consts/filters/statementsFilters';
import {
  IStatementPageActions,
  statementPageActions,
} from '../consts/pageActions/IStatementPageActions';

const StatementDetailsPage: FC = () => {
  const addAmount = useStatementAmount();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const statementQuery = useStatement({
    id: +id,
  });

  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IStatementPageActions
  >(statementsFilters, statementPageActions);

  const statement = statementQuery.data;

  const [editStatus, setEditStatus] = useState<boolean>(false);

  const [status, setStatus] = useState<string | null>();

  const changeStatus = useStatementChangeStatus({
    id: +id,
    status: status as ApplicationsStatusEnumKeys,
  });

  const form = useForm({
    initialValues: {
      amount: '',
      amountComment: '',
      id: statement?.id,
    },
  });

  return (
    <div className="application-width mx-auto flex">
      <PageMeta
        breadcrumbs={[
          { link: '/statements', title: t('statements.statements') },
        ]}
        openMenuKeys={['statements']}
        selectedMenuKeys={['statements']}
        title={t('statements.statements')}
      />
      {statementQuery.isLoading ? (
        <Skeleton />
      ) : !statement ? (
        <NotFoundResult />
      ) : (
        <div className="grid w-full grid-cols-8 gap-6">
          <div className="col-span-4 my-8 grid gap-5">
            <div className="grid gap-6">
              <div className="rounded-[24px] bg-white px-8 py-6">
                <div className="flex justify-between">
                  Заявление {statement.statementNumber || '-'}
                  {editStatus ? (
                    <form
                      className="flex w-full max-w-sm items-center gap-3 lg:w-2/3"
                      onSubmit={form.onSubmit(() => {
                        changeStatus
                          .mutateAsync()
                          .then(() => setEditStatus(false))
                          .catch((error) => notify);
                      })}
                    >
                      <Select
                        clearable
                        data={getApplicationsStatuses().map((type) => ({
                          label: type.label,
                          value: type.value,
                        }))}
                        onChange={setStatus}
                        placeholder="Статус"
                        value={status}
                        withAsterisk
                      />
                      <ActionIcon
                        aria-label="Settings"
                        type="submit"
                        variant="subtle"
                      >
                        <IconCheck
                          stroke={1.5}
                          style={{ height: '70%', width: '70%' }}
                        />
                      </ActionIcon>
                      <ActionIcon
                        aria-label="Settings"
                        onClick={() => setEditStatus(false)}
                        variant="subtle"
                      >
                        <IconX
                          stroke={1.5}
                          style={{ height: '70%', width: '70%' }}
                        />
                      </ActionIcon>
                    </form>
                  ) : (
                    <>
                      <ApplicationStatusTag status={statement.status} />
                      <ActionIcon
                        aria-label="Редактировать"
                        onClick={() =>
                          setPartialActioned({
                            action: 'change-statement-status',
                            actionId: String(statement.id) ?? '',
                            actionStatus: statement.status,
                          })
                        }
                        variant="subtle"
                      >
                        <IconEdit
                          stroke={1.5}
                          style={{ height: '70%', width: '70%' }}
                        />
                      </ActionIcon>
                    </>
                  )}
                </div>
                {statement.comment && (
                  <Alert
                    className="mt-5"
                    color="red"
                    icon={<IconInfoCircle />}
                    radius="lg"
                    title={statement.comment}
                    variant="transparent"
                  />
                )}
              </div>

              {[
                'WAITING_FOR_PAYMENT_APPLICATION',
                'IN_PROCESSING_PAYMENT',
                'PAYMENT_SENT',
                'STATEMENT_ACCEPTED',
              ].includes(statement.status) && (
                <div className="rounded-[24px] bg-white px-8 py-6">
                  {statement.amount && statement.amountComment ? (
                    <div>
                      <p className="text-center text-base font-normal">
                        Итоговая сумма страховой выплаты будет составлять:
                      </p>
                      <h2 className="text-center text-[42px] font-bold not-italic text-[#0B7B94]">
                        {statement.amount
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}{' '}
                        тг
                      </h2>
                      <p>{statement.amountComment}</p>
                    </div>
                  ) : (
                    <form
                      onSubmit={form.onSubmit(({ amount, amountComment }) => {
                        addAmount
                          .mutateAsync({
                            amount: amount,
                            amountComment: amountComment,
                            id: statement.id,
                          })
                          .catch(() => {
                            notify(
                              'error',
                              'Что-то пошло не так, повторите попытку позже.'
                            );
                          });
                      })}
                    >
                      <NumberInput
                        label="Сумма выплаты"
                        name="paymentAmount"
                        withAsterisk
                        {...form.getInputProps('amount')}
                        rightSection={<div style={{ display: 'none' }} />}
                      />
                      <Textarea
                        label="Комментарий"
                        name="comment"
                        {...form.getInputProps('amountComment')}
                      />
                      <Button
                        className="mt-5"
                        type="submit" /*style={{ marginTop: '20px' }}*/
                      >
                        {' '}
                        Отправить
                      </Button>
                    </form>
                  )}
                </div>
              )}

              <div className="rounded-[24px] bg-white px-8 py-6">
                <h3>Данные</h3>
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                  <div className="grid items-baseline ">
                    <Text c="#6D758F" fw={400} size="sm">
                      Номер договора:
                    </Text>
                    <Text c="#6D758F" fw={600} size="sm">
                      {statement.contractNumber}
                    </Text>
                  </div>

                  <div>
                    <Text c="#6D758F" fw={400} size="sm">
                      Статус:
                    </Text>
                    <ApplicationStatusTag status={statement.status} />
                  </div>

                  <div>
                    <Text color="#6D758F" fw={400} size="sm">
                      Застрахованный:
                    </Text>
                    <Text color="#464C5D" fw={500} size="md">
                      {statement.acquirer || '-'}
                    </Text>
                  </div>

                  <div>
                    <Text color="#6D758F" fw={400} size="sm">
                      Выгодоприобретатель:
                    </Text>
                    <Text color="#464C5D" fw={500} size="md">
                      {statement.beneficiary || '-'}
                    </Text>
                  </div>

                  <div className="items-center">
                    <Text color="#6D758F" fw={400} size="sm">
                      Дата и время заявления:
                    </Text>
                    <Text color="#6D758F" fw={400} size="sm">
                      {moment(statement.createdAt).format(
                        'DD MMMM YYYY, HH:mm'
                      )}
                    </Text>
                  </div>

                  <div className="items-center">
                    <Text color="#6D758F" fw={400} size="sm">
                      Дата и время последнего обн.:
                    </Text>
                    <Text color="#6D758F" fw={400} size="sm">
                      {moment(statement.updatedAt).format(
                        'DD MMMM YYYY, HH:mm'
                      )}
                    </Text>
                  </div>
                </div>
              </div>

              {statement.individualRequisiteDto && (
                <div className="rounded-[24px] bg-white px-8 py-6">
                  <h3>Данные о выплате</h3>
                  <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                    <div>
                      <Text color="#6D758F" fw={400} size="sm">
                        БИК и ли наименование банка:
                      </Text>
                      <Text color="#464C5D" fw={500} size="md">
                        {statement.individualRequisiteDto.bankName}
                      </Text>
                    </div>

                    <div>
                      <Text color="#6D758F" fw={400} size="sm">
                        Корреспонденский счет банка:
                      </Text>
                      <Text color="#464C5D" fw={500} size="md">
                        {statement.individualRequisiteDto.bankAccount}
                      </Text>
                    </div>

                    <div>
                      <Text color="#6D758F" fw={400} size="sm">
                        Счет получателя:
                      </Text>
                      <Text color="#464C5D" fw={500} size="md">
                        {statement.individualRequisiteDto.recipientsAccount}
                      </Text>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="col-span-4 my-8 grid gap-5 rounded-[24px] bg-white px-8 py-6">
            {statement.statementDocuments &&
            statement.statementDocuments.length > 0 ? (
              statement.statementDocuments.map((file) => (
                <div
                  className={`flex h-12 w-full items-center justify-between  rounded-md bg-[#F5F6FB] p-2`}
                  key={file.id}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex items-center">
                      <ActionIcon
                        aria-label="File"
                        size="lg"
                        variant="transparent"
                      >
                        <img alt="file" src="/images/icons/document-icon.svg" />
                      </ActionIcon>

                      {document.title}
                    </div>
                    <ApplicationStatusTag status={file.status} />
                    <ActionIcon
                      aria-label="Редактировать"
                      onClick={() =>
                        setPartialActioned({
                          action: 'change-document-status',
                          actionId: String(file.id) ?? '',
                          actionStatus: file.status,
                        })
                      }
                      variant="subtle"
                    >
                      <IconEdit
                        stroke={1.5}
                        style={{ height: '70%', width: '70%' }}
                      />
                    </ActionIcon>
                  </div>
                </div>
              ))
            ) : (
              <NotFoundResult
                subTitle="Пользователь еще не загрузил документы"
                title="Ничего не найдено"
              />
            )}
          </div>
        </div>
      )}
      <ChangeStatusModal
        fileStatus={actioned.actionStatus as ApplicationDocumentsStatusEnumKeys}
        id={actioned.actionId}
        isOpen={actioned.action === 'change-document-status'}
        onClose={clearParams}
      />
      <ChangeStatementStatusModal
        id={actioned.actionId}
        isOpen={actioned.action === 'change-statement-status'}
        onClose={clearParams}
        statementStatus={actioned.actionStatus as ApplicationsStatusEnumKeys}
      />
    </div>
  );
};

export default StatementDetailsPage;
