export type IEnumFileTypesKeys = keyof typeof IEnumFileTypes;
export type IEnumFileSubcategoryTypesKeys =
  keyof typeof IEnumFileSubcategoryTypes;

export enum IEnumFileTypes {
  CORPORATE_DOCUMENTS = 'Корпоративные документы',
  FINANCIAL_STATEMENTS = 'Финансовая отчетность',
  INFORMATION_ABOUT_PARTICIPATION_ASSOCIATIONS_UNIONS = 'Сведения об участии в ассоциациях и союзах',
  REGISTER_OF_INSURANCE_AGENTS = 'Реестр страховых агентов',
  REMUNERATION_INSURANCE_AGENTS = 'Вознаграждения страховых агентов'
}

export enum IEnumFileSubcategoryTypes {
  INSURANCE_RATES = 'Cтраховые тарифы',
  СOMPANY_INFO = 'Cправка о государственной регистрации компании',
}

export const getFileType = () => [
  {
    label: 'Финансовая отчетность',
    value: 'FINANCIAL_STATEMENTS',
  },
  {
    label: 'Корпоративные документы',
    value: 'CORPORATE_DOCUMENTS',
  },
  {
    label: 'Вознаграждения страховых агентов',
    value: 'REMUNERATION_INSURANCE_AGENTS',
  },
  {
    label: 'Реестр страховых агентов',
    value: 'REGISTER_OF_INSURANCE_AGENTS',
  },
  {
    label: 'Сведения об участии в ассоциациях и союзах',
    value: 'INFORMATION_ABOUT_PARTICIPATION_ASSOCIATIONS_UNIONS',
  },
];

export const getCorporateFileType = () => [
  {
    label: 'Cправка о государственной регистрации компании',
    value: 'СOMPANY_INFO',
  },
  {
    label: 'Cтраховые тарифы',
    value: 'INSURANCE_RATES',
  },
  {
    label: 'Правила страхования',
    value: 'INSURANCE_RULES',
  },
];

export const getCorporateFileYearType = () => [
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2025',
    value: '2025',
  },
  {
    label: '2026',
    value: '2026',
  },
  {
    label: '2027',
    value: '2027',
  },
  {
    label: '2028',
    value: '2028',
  },
  {
    label: '2029',
    value: '2029',
  },
  {
    label: '2030',
    value: '2030',
  },
  {
    label: '2031',
    value: '2031',
  },
  {
    label: '2032',
    value: '2032',
  },
  {
    label: '2033',
    value: '2033',
  },
  {
    label: '2034',
    value: '2034',
  },
];
