import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { variantColorResolver } from '@assets/theme/colorsResolver';
import { mantineTheme } from '@assets/theme/mantineTheme';
import { PageMetaContextProvider } from '@context/PageMetaContext';
import { UserContextProvider } from '@context/UserContext';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import App from './App';
import reportWebVitals from './utils/reportWebVitals';

import '@assets/styles/index.scss';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/notifications/styles.css';
import 'dayjs/locale/ru';
import '@utils/configs/axiosConfig';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <StrictMode>
      <MantineProvider
        theme={{
          ...mantineTheme,
          activeClassName: '.active',
          variantColorResolver: variantColorResolver,
        }}
      >
        <Notifications position="top-right" />
        <ModalsProvider>
          <DatesProvider settings={{ locale: 'ru' }}>
            <QueryClientProvider client={queryClient}>
              <HelmetProvider>
                <PageMetaContextProvider>
                  <UserContextProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </UserContextProvider>
                </PageMetaContextProvider>
              </HelmetProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </DatesProvider>
        </ModalsProvider>
      </MantineProvider>
    </StrictMode>
  );
}

reportWebVitals();
