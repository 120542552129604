import { Link } from 'react-router-dom';

import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment';

import { Text, Tooltip } from '@mantine/core';
import { ApplicationStatusTag } from '@modules/statements/components/atoms/ApplicationStatusTag';

import { IStatement } from '@/types/IStatement';

export const statementsColumns: MRT_ColumnDef<IStatement>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorFn: (row) => (
      <Tooltip label={row.statementRu ?? '-'}>
        <div className="flex items-center">
          <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
            {row.statementRu ?? '-'}
          </Text>
        </div>
      </Tooltip>
    ),
    accessorKey: 'fileNameKz',
    header: 'Заявление',
    size: 150,
  },
  {
    accessorFn: (row) => (
      <Link to={`/statements/${row.id}`}>{row.contractNumber ?? '-'}</Link>
    ),
    accessorKey: 'fileNameRu',
    header: '# Договора',
    size: 150,
  },
  {
    accessorFn: (row) => <ApplicationStatusTag status={row.status} />,
    accessorKey: 'status',
    header: 'Статус',
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.updatedAt ? moment(row.updatedAt).format('DD.MM.YY') : '-'}
      </span>
    ),
    accessorKey: 'updatedAt',
    header: 'Последние изменения',
  },
];
