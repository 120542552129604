import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { useNews } from '@api/news/useNews';
import { Search } from '@components/atoms/Search';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { IEnumNewsTypesKeys } from '@consts/enums/IEnumNewsTypes copy';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, Chip, Group, Pagination, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import NewsSkeleton from '../components/atoms/NewsSkeleton';
import NewsCard from '../components/molecules/NewsCard';
import { ConfirmDeleteModal } from '../components/organisms/ConfirmDeleteModal';
import { newsFilters } from '../consts/filters/newsFilters';
import {
  INewsPageActions,
  newsPageActions,
} from '../consts/pageActions/INewsPageActions';

const NewsPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [value, setValue] = useState<IEnumNewsTypesKeys>('NEWS');

  const { actioned, clearParams, filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    INewsPageActions
  >(newsFilters, newsPageActions);

  const newsQuery = useNews({
    ...filtered,
    postType: value,
    searchQuery,
  });
  const news = newsQuery.data?.content;

  const handleTabChange = (value: string) => {
    setValue(value as IEnumNewsTypesKeys);
  };

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/news', title: 'Новости' }]}
        openMenuKeys={['news']}
        selectedMenuKeys={['news']}
        title="Новости"
      />
      <div className="flex pt-6">
        <Text fw={600} mr={12} size="lg">
          Все новости
        </Text>
        <span className="rounded-2xl bg-[#EBF3FD] px-2 text-[#1F4A62]">
          {newsQuery.data?.totalElements}
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="flex flex-col gap-4">
            <div className="col-span-3 mt-5 flex items-baseline justify-between gap-4">
              <Chip.Group
                multiple={false}
                onChange={(value) => handleTabChange(value)}
                value={value}
              >
                <Group className="gap-0" justify="start">
                  <Chip value="NEWS" variant="outline">
                    Новости
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      0
                    </span>
                  </Chip>
                  <Chip value="USEFUL_ARTICLE" variant="outline">
                    Полезные статьи
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      0
                    </span>
                  </Chip>
                  {/* <Chip value="EXECUTIVES" variant="outline">
                    Руководящие работники
                    <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      {employeesCount?.executivesCount}
                    </span>
                  </Chip> */}
                </Group>
              </Chip.Group>
            </div>

            <div className="col-span-3 mt-1 flex items-baseline justify-end gap-4">
              <Search onChange={setSearchQuery} searchQuery={searchQuery} />
              <Link to="/news/create">
                <Button leftSection={<IconPlus />}>Создать</Button>
              </Link>
            </div>

            {newsQuery.isLoading || (!!news?.length && newsQuery.isLoading) ? (
              <>
                <NewsSkeleton />
                <NewsSkeleton />
              </>
            ) : !news?.length ? (
              <NotFoundResult
                subTitle="В данном разделе отсутствуют новости"
                title=""
              />
            ) : (
              news?.map((post) => <NewsCard key={post.id} post={post} />)
            )}
          </div>

          {news && news.length !== 0 && (
            <div className="mt-5 flex justify-between gap-4">
              <Pagination
                classNames={{
                  control:
                    'border-gray-200 border-[#DEE2E6] data-[active=true]:border-primary data-[active=true]:text-white',
                }}
                onChange={(page) => onFilter({ page: page - 1 })}
                total={newsQuery.data?.totalPages || 1}
                value={
                  typeof filtered.page == 'string'
                    ? Number.parseInt(filtered.page) + 1
                    : Number(filtered.page) + 1 || 1
                }
              />
            </div>
          )}
        </div>
      </div>

      <ConfirmDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />
    </div>
  );
};

export default NewsPage;
