import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import moment from 'moment';

import { IEnumPostType } from '@consts/enums/IEnumPostTypes';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { ActionIcon, Badge, Image, Text } from '@mantine/core';
import { newsFilters } from '@modules/news/consts/filters/newsFilters';
import {
  INewsPageActions,
  newsPageActions,
} from '@modules/news/consts/pageActions/INewsPageActions';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { INewsPost } from '@/types/INewsPost';

export default function NewsCard({ post }: { post: INewsPost }) {
  const navigate = useNavigate();
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    INewsPageActions
  >(newsFilters, newsPageActions);

  return (
    <div className="delay-80 flex justify-between  rounded-md bg-white shadow-sm transition ease-in-out hover:shadow-md">
      <div className="flex">
        <div>
          <Image
            alt="Norway"
            fallbackSrc="https://placehold.co/600x400/F1F4F7/1A2434?text=Не удалось загрузить фото"
            fit="cover"
            h={120}
            maw={200}
            radius="md"
            src={language == 'kz' ? post.imageKz : post.imageRu}
            w={200}
          />
        </div>

        <div className="ml-5 flex flex-col justify-evenly">
          <div className="flex items-center">
            <Text c="dimmed">
              {moment(post.createdAt).format('DD.MM.YYYY')}
            </Text>
            <Badge color="cyan" ml={10} size="xs">
              {IEnumPostType[post.postType]}
            </Badge>
          </div>
          <Text fw={600}>{language == 'kz' ? post.titleKz : post.titleRu}</Text>
        </div>
      </div>

      <div className="items-end self-center p-5">
        <Link to={`/news/${post.id}`}>
          <ActionIcon
            aria-label="Посмотреть"
            radius="xl"
            size="lg"
            variant="light"
          >
            <IconEye stroke={1.5} style={{ height: '60%', width: '60%' }} />
          </ActionIcon>
        </Link>
        <ActionIcon
          aria-label="Редактировать"
          ml={15}
          onClick={() => navigate(`/news/edit/${post.id}`)}
          radius="xl"
          size="lg"
          variant="light"
        >
          <IconEdit stroke={1.5} style={{ height: '60%', width: '60%' }} />
        </ActionIcon>
        <ActionIcon
          aria-label="Удалить"
          color="red"
          ml={15}
          onClick={() => {
            setPartialActioned({
              action: 'delete',
              actionId: String(post.id) ?? '',
            });
          }}
          radius="xl"
          size="lg"
          variant="light"
        >
          <IconTrash stroke={1.5} style={{ height: '60%', width: '60%' }} />
        </ActionIcon>
      </div>
    </div>
  );
}
