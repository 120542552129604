import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

type IEmployeeResponse = AxiosResponse<{
  boardDirectorsCount: number;
  executivesCount: number;
  governanceCount: number;
}>;

export function useEmployeeCount() {
  return useQuery<IEmployeeResponse, AxiosError>(
    [queryKeys.employeeCount],
    () => api.get(`/admin/api/employees/count-categories`)
  );
}
