import qs from 'qs';

import { IFilters, IFiltersPropsDefault } from '@/types/common/IFilters';
import { IPageActions } from '@/types/common/IPageActions';

export function initActioned<IActionProps = void>(
  actions?: IPageActions
): Partial<IActionProps> {
  const search = qs.parse(decodeURIComponent(window.location.search), {
    comma: true,
    ignoreQueryPrefix: true,
  });
  const actioned = {};

  for (const [key, action] of Object.entries(actions || {})) {
    const actionedValue = search[key]; // find actioned value in window search by key

    if (actionedValue && (!action.allowed || action.allowed(actionedValue))) {
      actioned[key] = actionedValue;
    } else if (action.default) {
      actioned[key] = action.default;
    }
  }

  return actioned;
}

export function initFiltered<IFiltersProps>(
  filters?: IFilters
): Partial<IFiltersProps> {
  if (filters) {
    const filtered: IFiltersPropsDefault = { page: 0, size: 10 };

    const windowSearch = qs.parse(decodeURIComponent(window.location.search), {
      comma: true,
      ignoreQueryPrefix: true,
    });

    for (const [filterKey, filter] of Object.entries(filters || {})) {
      const { queries } = filter;

      (queries || [{}]).forEach((query) => {
        const key = query.key || filterKey;
        const filteredValue = windowSearch[key];

        if (filteredValue && (!query.allowed || query.allowed(filteredValue))) {
          filtered[key] = filteredValue;
        } else if (query.default) {
          filtered[key] = query.default;
        }
      });
    }

    return filtered;
  }
  return {};
}

export function toUrlParams({
  actioned,
  actions,
  filtered,
  filters,
}: {
  actioned?: object;
  actions?: IPageActions;
  filtered?: IFiltersPropsDefault;
  filters?: IFilters;
}) {
  const filteredUrlSearch = {};
  const actionedUrlSearch = {};

  if (filtered) {
    for (const [filterKey, filter] of Object.entries(filters || {})) {
      const { queries } = filter;

      (queries || [{}]).forEach((query) => {
        const key = query.key || filterKey;
        const filteredValue = filtered?.[key];

        if (
          filteredValue !== undefined &&
          filteredValue !== null &&
          filteredValue !== '' &&
          filteredValue != query.default
        ) {
          filteredUrlSearch[key] = filteredValue;
        }
      });
    }
  }

  if (actioned) {
    for (const [key, actionedValue] of Object.entries(actioned || {})) {
      if (actionedValue && actionedValue != actions?.[key].default) {
        actionedUrlSearch[key] = actionedValue;
      }
    }
  }

  return qs.stringify(
    { ...filteredUrlSearch, ...actionedUrlSearch },
    { arrayFormat: 'comma', encode: false }
  );
}
