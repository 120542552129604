import { Route, Routes } from 'react-router-dom';

import NewsCreatePage from './pages/NewsCreatePage';
import NewsDetailsPage from './pages/NewsDetailsPage';
import NewsEditPage from './pages/NewsEditPage';
import NewsPage from './pages/NewsPage';

export const NewsRouter = () => {
  return (
    <Routes>
      <Route element={<NewsPage />} index />
      <Route element={<NewsCreatePage />} path="/create" />
      <Route element={<NewsEditPage />} path="/edit/:id" />
      <Route element={<NewsDetailsPage />} path="/:id" />
    </Routes>
  );
};
