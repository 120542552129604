import { Route, Routes } from 'react-router-dom';

import FilesPage from './pages/FilesPage';
import FilesUploadPage from './pages/FilesUploadPage';

export const FilesRouter = () => {
  return (
    <Routes>
      <Route element={<FilesPage />} index />
      <Route element={<FilesUploadPage />} path="/upload" />
    </Routes>
  );
};
