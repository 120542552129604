import { IPageActions } from '@/types/common/IPageActions';

enum IEnumEmployeePageActions {
  'delete',
  'cancelCreate',
}

export type IEmployeePageActions = {
  action: keyof typeof IEnumEmployeePageActions;
  actionId: string;
};

export const employeePageActions: IPageActions<IEmployeePageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumEmployeePageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
