import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';

type IEmployeeDeleteRequest = { id: number };

type IEmployeeDeleteResponse = AxiosResponse<void>;

export function useEmployeeDelete() {
  const queryClient = useQueryClient();

  return useMutation<
    IEmployeeDeleteResponse,
    AxiosError,
    IEmployeeDeleteRequest
  >(({ id }) => api.delete(`/admin/api/employees/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.employees]),
        queryClient.invalidateQueries([queryKeys.employeeCount]);
    },
  });
}
