import { useEffect, useMemo, useRef, useState } from 'react';

import { AxiosError } from 'axios/index';
import {
  MantineReactTable,
  MRT_Column,
  type MRT_ColumnDef,
  MRT_PaginationState,
  MRT_ShowHideColumnsButton,
  MRT_TableOptions,
  MRT_VisibilityState,
} from 'mantine-react-table';

import { mantineCommonTableProps } from '@consts/mantineCommonTableProps';
import { ActionIcon, Menu, Tooltip } from '@mantine/core';
import { IconDots, IconInfoCircle } from '@tabler/icons-react';
import { UseQueryResult } from '@tanstack/react-query';

import { TableFilter } from '@/types/common/IFilters';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IQueryTableAction } from '@/types/common/IQueryTableAction';
import { ITableContent } from '@/types/common/ITableContent';

import { QueryTableHeader } from './QueryTableHeader';

type ITableType<T extends ITableContent> = {
  actions?: (T: T) => IQueryTableAction[];
  columns: MRT_ColumnDef<T>[];
  data: any;
  empty?: { description?: string; title: string };
  hideColumns?: boolean;
  isPaginated?: boolean;
  query: UseQueryResult<IPageAbleResponse<T>, AxiosError>;
  tableFilter: TableFilter;
};

export const QueryTable = <T extends ITableContent>({
  actions,
  columns,
  data,
  empty,
  hideColumns = false,
  isPaginated = true,
  query: { isFetched, isFetching, isLoading },
  tableFilter: { filtered, filters, onFilter },
}: ITableType<T>) => {
  const isFirstRender = useRef(true);
  const pageAble = data;
  const [total, setTotal] = useState(pageAble?.totalElements || 1);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    // FIXME: pagination
    pageSize: isPaginated ? 20 : 100,
  });

  const filteredPage = useMemo(
    () =>
      typeof filtered.page === 'string'
        ? parseInt(filtered.page)
        : filtered.page ?? 0,
    [filtered.page]
  );

  useEffect(() => {
    if (pagination) {
      onFilter({
        page: pagination.pageIndex || 0,
        size: pagination.pageSize || 20,
      });
    }
  }, [pagination.pageIndex]);

  const columnsMantine: MRT_ColumnDef<T>[] = useMemo(() => {
    return columns.map((col) => {
      return {
        ...col,
        Header: ({ column }: { column: MRT_Column<T> }) => (
          <QueryTableHeader
            column={column}
            tableFilterProps={{ filtered, filters, onFilter }}
          />
        ),
        accessorFn:
          isLoading || (isFetching && !data?.content.length) || !isFetched
            ? undefined
            : col.accessorFn,
      };
    });
  }, [
    columns,
    filters,
    filtered,
    isLoading,
    isFetching,
    isFetched,
    data?.content,
  ]);

  useEffect(() => {
    if (!isLoading) {
      setTotal(pageAble?.totalElements || 1);
      if (filteredPage + 1 > (pageAble?.totalPages || 1)) {
        onFilter({ page: pageAble?.totalPages || 0 });
      }
    }
  }, [data]);

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {}
  );

  useEffect(() => {
    const savedColumnVisibility = localStorage.getItem(
      'mrt_columnVisibility_table'
    );
    if (savedColumnVisibility) {
      setColumnVisibility(JSON.parse(savedColumnVisibility));
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) return;
    if (Object.keys(columnVisibility).length !== 0) {
      localStorage.setItem(
        'mrt_columnVisibility_table',
        JSON.stringify(columnVisibility)
      );
    }
  }, [columnVisibility]);

  return (
    <MantineReactTable
      {...(mantineCommonTableProps as Partial<MRT_TableOptions<T>>)}
      columns={columnsMantine}
      data={
        !isFetched ? ([{}, {}, {}, {}, {}, {}] as T[]) : data?.content || []
      }
      enableDensityToggle
      enableHiding
      enableRowActions={!!actions?.length}
      enableSorting={false}
      initialState={{
        density: 'xs',
        showColumnFilters: false,
        showGlobalFilter: false,
        showSkeletons: true,
      }}
      localization={{ actions: 'Действия' }}
      mantinePaginationProps={{
        classNames: {
          control:
            'data-[active=true]:bg-[#228BE6] border-[#228BE6] data-[active=true]:border-[#228BE6] data-[active=true]:text-white',
          root: 'pt-4 !justify-end',
        },
        disabled: isFetching,
        showRowsPerPage: false,
      }}
      mantineTableBodyRowProps={({ row }) => ({
        onClick: (event) => {
          console.info(row);
        },
        sx: {
          cursor: 'pointer',
        },
      })}
      mantineTableContainerProps={{ className: 'overflow-visible' }}
      manualPagination={true}
      onColumnVisibilityChange={setColumnVisibility}
      onPaginationChange={setPagination}
      renderBottomToolbarCustomActions={() => (
        <span className="text-sm font-normal text-[#8E8E93]">
          Показано {pageAble?.numberOfElements} из {pageAble?.totalElements}
        </span>
      )}
      renderEmptyRowsFallback={() => (
        <div className="flex flex-col items-center justify-center gap-4 py-8">
          <div className="flex flex-col gap-2 text-center">
            <span className="text-primary-900 text-lg font-semibold">
              {empty?.title}
            </span>
            <span className="text-sm font-medium text-gray-500">
              {empty?.description}
            </span>
          </div>
        </div>
      )}
      renderRowActions={({ row }) => {
        return (
          actions?.(row.original).length !== 0 &&
          (actions?.(row.original).length == 1 ? (
            <>
              {actions?.(row.original).map(({ icon, onClick }, index) => (
                <ActionIcon key={index} onClick={onClick} variant="light">
                  {icon}
                </ActionIcon>
              ))}
            </>
          ) : (
            <Menu variant="actions" withinPortal>
              <Menu.Target>
                <div>
                  <IconDots className="hover:bg-primary-101 h-4 w-4 cursor-pointer rounded-full text-3xl text-[#6C757D]" />
                </div>
              </Menu.Target>
              <Menu.Dropdown className="overflow-visible">
                {actions?.(row.original).map(
                  ({ icon, onClick, title }, index) => (
                    <Menu.Item key={index} leftSection={icon} onClick={onClick}>
                      {title}
                    </Menu.Item>
                  )
                )}
              </Menu.Dropdown>
            </Menu>
          ))
        );
      }}
      renderToolbarInternalActions={({ table }) => {
        return hideColumns ? (
          <div className="flex w-full flex-row items-center justify-end">
            <MRT_ShowHideColumnsButton table={table} title="Show columns" />
            <Tooltip label="Press Show columns to add more columns to the table">
              <IconInfoCircle className="text-[#228BE6]" />
            </Tooltip>
          </div>
        ) : (
          <></>
        );
      }}
      rowCount={total}
      state={{
        columnVisibility: columnVisibility,
        isLoading: !isFetched,
        pagination: { pageIndex: filteredPage, pageSize: filtered.size || 10 },
        showProgressBars: isFetching && !isLoading,
        showSkeletons: !isFetched,
      }}
    />
  );
};
