import { FC, useState } from 'react';

import { useRequestChangeStatus } from '@api/requests/useRequestChangeStatus.ts';
import { useRequests } from '@api/requests/useRequests';
import { QueryTable } from '@components/organisms/QueryTable';
import {
  getRequestsStatuses,
  RequestsStatusEnumKeys,
} from '@consts/enums/IEnumRequestsStatus';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Chip, Group, Select, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { requestsColumns } from '../consts/columns/requestsColumns';
import { requestsFilters } from '../consts/filters/requestsFilters';
import {
  IRequestPageActions,
  requestsPageActions,
} from '../consts/pageActions/IStatementPageActions';

const RequestsPage: FC = () => {
  const [value, setValue] = useState<'LIFE' | 'OSNS' | 'INSURANCE_CASE'>(
    'LIFE'
  );
  const [status, setStatus] = useState<string | null>();

  const { actioned, filtered, onFilter, setPartialActioned } =
    useUrlFilteredActioned<IPageAbleRequest, IRequestPageActions>(
      requestsFilters,
      requestsPageActions
    );

  const requestsQuery = useRequests({
    ...filtered,
    status: (status as RequestsStatusEnumKeys) || null,
    type: value,
  });

  const handleTabChange = (value: string) => {
    setValue(value as 'LIFE' | 'OSNS' | 'INSURANCE_CASE');
  };

  const changeStatus = useRequestChangeStatus({
    id: Number(actioned.actionId),
    status:
      actioned.actionStatus == 'NEW_APPLICATION'
        ? 'PROCESSED_APPLICATION'
        : 'NEW_APPLICATION',
  });

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/requests', title: 'Заявки' }]}
        openMenuKeys={['requests']}
        selectedMenuKeys={['requests']}
        title="Заявки"
      />
      <div className="flex pt-6">
        <Text fw={600} mr={12} size="lg">
          Все заявки
        </Text>
        <span className="rounded-2xl bg-[#EBF3FD] px-2 text-[#1F4A62]">
          {requestsQuery.data?.totalElements}
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="flex flex-col gap-4">
            <div className="col-span-3 mt-5 flex items-baseline justify-between gap-4">
              <Chip.Group
                multiple={false}
                onChange={(value) => handleTabChange(value)}
                value={value}
              >
                <Group className="gap-0" justify="start">
                  <Chip value="LIFE" variant="outline">
                    Страхование жизни
                    {/* <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      0
                    </span> */}
                  </Chip>
                  <Chip value="OSNS" variant="outline">
                    Cтрахование работников
                    {/* <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      0
                    </span> */}
                  </Chip>
                  <Chip value="INSURANCE_CASE" variant="outline">
                    Cтраховые случаи
                    {/* <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      0
                    </span> */}
                  </Chip>
                </Group>
              </Chip.Group>
            </div>
            <div className="flex items-baseline justify-end gap-4">
              <Select
                clearable
                data={getRequestsStatuses().map((type) => ({
                  label: type.label,
                  value: type.value,
                }))}
                onChange={setStatus}
                placeholder="Статус"
                value={status}
                withAsterisk
              />
            </div>
            <div className="overflow-x-scroll rounded-xl bg-white p-5">
              <QueryTable
                actions={(row) => [
                  {
                    icon:
                      row.status == 'NEW_APPLICATION' ? (
                        <IconCheck />
                      ) : (
                        <IconX />
                      ),
                    onClick: () => {
                      setPartialActioned({
                        action: 'change-status',
                        actionId: String(row.id) ?? '',
                        actionStatus: row.status,
                      });

                      changeStatus.mutateAsync();
                    },
                    title: '',
                  },
                ]}
                columns={requestsColumns}
                data={requestsQuery.data}
                empty={{
                  description: 'В данном разделе отсутствуют загруженные файлы',
                  title: '',
                }}
                query={requestsQuery}
                tableFilter={{
                  filtered,
                  filters: requestsFilters,
                  onFilter,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestsPage;
