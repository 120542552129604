import { Navigate } from 'react-router-dom';

import { useCurrent } from '@api/auth/useCurrent';
import { sidebars } from '@consts/sidebars';
import { Skeleton } from '@mantine/core';

const IndexRoute = () => {
  const me = useCurrent();

  const navbar = sidebars().find((nav) => nav.key == 'all');

  if (navbar?.link) return <Navigate replace to={navbar.link} />;

  if (me.isFetching) {
    return (
      <div className="md:p-15 mt-5 rounded-2xl bg-white p-10">
        <Skeleton className="h-24 w-full rounded-lg" />
        <Skeleton className="h-24 w-full rounded-lg" />
        <Skeleton className="h-24 w-full rounded-lg" />
      </div>
    );
  }

  return <div />;
};

export default IndexRoute;
