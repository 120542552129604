import { Link } from 'react-router-dom';

import { MRT_ColumnDef } from 'mantine-react-table';

import { IUser } from '@/types/IUser';

export const clientsColumns: MRT_ColumnDef<IUser>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorFn: (row) => (
      <Link to={`/clients/${row.id}`}>
        {`${row.firstName + ' ' + row.lastName}` ?? '-'}
      </Link>
    ),
    accessorKey: 'firstName',
    header: 'ФИО',
  },
  {
    accessorFn: (row) => (
      <div className="flex flex-col">
        <span className="whitespace-nowrap font-semibold">
          {row.identifierType == 'IIN' ? 'ИИН' : 'БИН'}
        </span>
        <span className="whitespace-nowrap">{row.identifier ?? '-'}</span>
      </div>
    ),
    accessorKey: 'identifier',
    header: 'ИИН/БИН',
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.phone ?? '-'}</span>
    ),
    accessorKey: 'phone',
    header: 'Номер телефона',
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.email ?? '-'}</span>
    ),
    accessorKey: 'email',
    header: 'Почта',
  },
];
