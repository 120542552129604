export type RuTranslationsType = typeof ruTranslations;
export const ruTranslations = {
  translation: {
    auth: {
      auth: 'Авторизация',
      changeEmail: 'Изменить почту',
      checkEmail: 'Проверьте свою почту!',
      confirmPassword: 'Повторите Пароль',
      email: 'Электронная почта',
      login: 'Логин',
      password: 'Пароль',
      username: 'Имя пользователя',
      welcome: 'Добро пожаловать',
    },
    chooseLanguage: 'Выберите язык',
    goBack: 'Вернуться назад',
    statuses: {
      ACCEPTED: 'Принято',
      ACTIVE: 'Активен',
      EXPIRED: 'Истек',
      IN_PROCESSING_DOCUMENTS: 'В обработке',
      IN_PROCESSING_PAYMENT: 'В обработке',
      NEW_APPLICATION: 'Новый',
      PAYMENT_SENT: 'Направлена выплата',
      PROCESSED_APPLICATION: 'Обработан',
      REJECTED: 'Отклонено',
      REVIEWING: 'В обработке',
      STATEMENT_ACCEPTED: 'Заявление принято',
      WAITING_FOR_DOCUMENTS: 'Ожидаем документы',
      WAITING_FOR_PAYMENT_APPLICATION: 'Ожидаем заявления на выплату',
    },
  },
};
