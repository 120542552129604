import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useClient } from '@api/clients/useClient';
import { useContracts } from '@api/contracts/useContracts';
import { useStatements } from '@api/statements/useStatements';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Pagination, Skeleton, Text } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import ContractCard from '../components/molecules/ContractCard';
import { CreateStatementModal } from '../components/organisms/CreateStatementModal';
import { statementsColumns } from '../consts/columns/statementsColumns';
import { contractsFilters } from '../consts/filters/contractsFilters';
import {
  contractsPageActions,
  IContractsPageActions,
} from '../consts/pageActions/IContractsPageActions';

const ClientDetailsPage: FC = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const clientQuery = useClient({
    id: +id,
  });
  const client = clientQuery.data;

  const { actioned, clearParams, filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    IContractsPageActions
  >(contractsFilters, contractsPageActions);

  const contractsQuery = useContracts({
    ...filtered,
    size: 2,
    status: undefined,
    userId: client?.id,
  });
  const contracts = contractsQuery.data?.content;

  // FIXME: fix pagination
  const statementsQuery = useStatements({
    page: 0,
    size: 100,
    status: null,
    userId: client?.id,
  });

  const statements = statementsQuery.data?.content;

  return (
    <div className="application-width mx-auto flex flex-col gap-5">
      <PageMeta
        breadcrumbs={[{ link: '/clients', title: t('clients.clients') }]}
        openMenuKeys={['clients']}
        selectedMenuKeys={['clients']}
        title={t('clients.clients')}
      />
      {clientQuery.isLoading ? (
        <Skeleton />
      ) : !client ? (
        <NotFoundResult />
      ) : (
        <div className="grid w-full grid-cols-4 gap-6">
          <div className="col-span-4 my-8 grid gap-5">
            <div className="grid gap-6">
              <div className="rounded-[24px] bg-white px-8 py-6">
                <h3>{`${client.firstName} ${client.lastName}`}</h3>
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                  <div className="grid items-baseline ">
                    <Text color="#6D758F" fw={400} size="sm">
                      ИИН или БИН:
                    </Text>
                    <Text color="#6D758F" fw={600} size="sm">
                      {client.identifierType == 'IIN' ? 'ИИН' : 'БИН'}
                    </Text>
                  </div>

                  <div>
                    <Text color="#6D758F" fw={400} size="sm">
                      ИИН/БИН:
                    </Text>
                    <Text color="#6D758F" fw={600} size="sm">
                      {client.identifier || '-'}
                    </Text>
                  </div>

                  <div>
                    <Text color="#6D758F" fw={400} size="sm">
                      Номер телефона:
                    </Text>
                    <Text color="#464C5D" fw={500} size="md">
                      {client.phone || '-'}
                    </Text>
                  </div>

                  <div>
                    <Text color="#6D758F" fw={400} size="sm">
                      Почта:
                    </Text>
                    <Text color="#464C5D" fw={500} size="md">
                      {client.email || '-'}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-3 gap-4">
        <h2>Договоры клиента</h2>
        <div className="col-span-3">
          {contractsQuery.isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
            </>
          ) : !contracts ? (
            <NotFoundResult subTitle={t('applications.notFound')} title="" />
          ) : (
            <div className="relative flex flex-col justify-center gap-6 md:flex-row">
              {contracts.map((contract) => (
                <ContractCard contract={contract} key={contract.id} />
              ))}
            </div>
          )}
          {contracts && contracts.length !== 0 && (
            <div className="application-width mx-auto mb-10 mt-5 flex justify-end gap-4">
              <Pagination
                classNames={{
                  control:
                    'border-gray-200 border-[#DEE2E6] data-[active=true]:border-primary data-[active=true]:text-white',
                }}
                onChange={(page) => onFilter({ page: page - 1 })}
                total={contractsQuery.data?.totalPages || 1}
                value={
                  typeof filtered.page == 'string'
                    ? Number.parseInt(filtered.page) + 1
                    : Number(filtered.page) + 1 || 1
                }
              />
            </div>
          )}
        </div>
      </div>

      <div className="overflow-x-scroll rounded-xl bg-white p-5">
        <h2>Заявления клиента</h2>
        <QueryTable
          columns={statementsColumns}
          data={statementsQuery.data}
          empty={{
            description: 'В данном разделе отсутствуют загруженные файлы',
            title: '',
          }}
          isPaginated={false}
          query={statementsQuery}
          tableFilter={{
            filtered,
            filters: {
              page: { queries: [{ default: 0 }] },
              size: {
                queries: [
                  {
                    default: 5,
                  },
                ],
              },
              sort: { queries: [{ default: '' }] },
            },
            onFilter,
          }}
        />
      </div>

      <CreateStatementModal
        id={actioned.actionId}
        isOpen={actioned.action === 'create'}
        onClose={clearParams}
        premiumInsurance={actioned.premiumInsurance}
        sumInsured={actioned.sumInsured}
        type={actioned.type}
      />
    </div>
  );
};

export default ClientDetailsPage;
