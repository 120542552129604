import { AxiosError, AxiosResponse } from 'axios';
import { number, object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IFile } from '@/types/IFile';

export type IFileUpdateRequestParams = Pick<
  IFile,
  'fileNameKz' | 'fileNameRu'
> & {
  fileId: number;
};

type IFileUpdateResponse = void;

export const fileUpdateInitial: IFileUpdateRequestParams = {
  fileId: 0,
  fileNameKz: '',
  fileNameRu: '',
};

export const fileUpdateValidation: ObjectSchema<IFileUpdateRequestParams> =
  object({
    fileId: number().required('Поле обязательно для заполнения'),
    fileNameKz: string().required('Поле обязательно для заполнения'),
    fileNameRu: string().required('Поле обязательно для заполнения'),
  });

export function useFileUpdate({ ...params }: IFileUpdateRequestParams) {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<IFileUpdateResponse>, AxiosError, void>(
    () => api.put(`/admin/api/files`, {}, { params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.files]),
          queryClient.invalidateQueries([queryKeys.employeeCount]);
      },
    }
  );
}
