import { useUserContext } from '@context/UserContext';

export default function ProfileAvatar() {
  const { userMe } = useUserContext();

  return (
    <>
      <div className="grid h-8 w-8 shrink-0 content-center rounded-full border-2 border-[#1C7ED7] bg-[#E6F2FC] text-center md:h-8 md:w-8">
        <span className="text-xs uppercase text-[#1C7ED7] md:text-[13px]">
          {userMe?.firstName?.[0]} {userMe?.lastName?.[0] || 'AA'}
        </span>
      </div>
    </>
  );
}
