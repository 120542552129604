export const queryKeys = {
  client: 'client',
  clients: 'clients',
  contracts: 'contracts',
  employee: 'employee',
  employeeCount: 'employeeCount',
  employees: 'employees',
  file: 'file',
  files: 'files',
  filesCount: 'filesCount',
  news: 'news',
  newsCreate: 'newsCreate',
  newsPost: 'newsPost',
  requests: 'requests',
  statement: 'statement',
  statements: 'statements',
  tokenAccess: 'tokenAccess',
  tokenRefresh: 'tokenRefresh',
  user: 'user',
};
