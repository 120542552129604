import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IEmployee } from '@/types/IEmployee';

type IEmployeeRequest = { id: number };

type IEmployeeResponse = AxiosResponse<IEmployee>;

export function useEmployee(params: IEmployeeRequest) {
  return useQuery<IEmployeeResponse, AxiosError>(
    [queryKeys.employee, params],
    () => api.get(`/admin/api/employees/${params.id}`),
    { enabled: !!params.id }
  );
}
