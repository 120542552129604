import { Link } from 'react-router-dom';

import { SidebarInterface } from '@consts/sidebars';
import { NavLink } from '@mantine/core';

function NavLinkWrapper({
  children,
  isLink,
  link,
}: {
  children: JSX.Element;
  isLink: boolean;
  link: string;
}) {
  if (isLink) {
    return <Link to={link}>{children}</Link>;
  }
  return children;
}

export function SidebarNavLink({
  item,
  openMenuKeys,
  opened,
  parentKey,
  parentLink,
  selectedMenuKeys,
}: {
  item: SidebarInterface;
  openMenuKeys?: string[];
  opened: boolean;
  parentKey?: string;
  parentLink?: string;
  selectedMenuKeys?: string[];
}) {
  const currentKey = parentKey ? `${parentKey}-${item.key}` : item.key;
  const currentLink = parentLink ? `${parentLink}/${item.link}` : item.link;
  const isActive = selectedMenuKeys?.includes(currentKey);
  const isOpen = openMenuKeys?.includes(currentKey);
  const Icon = item.icon;

  return (
    <NavLinkWrapper isLink={!item.children?.length} link={currentLink || ''}>
      <NavLink
        active={isActive}
        childrenOffset={0}
        className={`${isActive ? 'active' : isOpen ? 'open' : ''} ${
          opened ? 'p-4' : 'px-6 py-3'
        } ${'mt-2'}`}
        defaultOpened={isOpen}
        key={currentKey}
        label={opened ? undefined : item.title}
        leftSection={Icon ? <Icon stroke="1.2" /> : undefined}
      >
        {item.children?.map((subItem) => (
          <SidebarNavLink
            item={subItem}
            key={`subItem-${currentKey}-${subItem.key}`}
            opened={opened}
            openMenuKeys={openMenuKeys}
            parentKey={currentKey}
            parentLink={currentLink}
            selectedMenuKeys={selectedMenuKeys}
          />
        ))}
      </NavLink>
    </NavLinkWrapper>
  );
}
