import { AxiosError, AxiosResponse } from 'axios';

import { RequestsStatusEnumKeys } from '@consts/enums/IEnumRequestsStatus';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';
type IStatementUpdateResponse = void;

export function useRequestChangeStatus({
  ...params
}: {
  id: number;
  status: RequestsStatusEnumKeys;
}) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<IStatementUpdateResponse>, AxiosError>(
    () => api.put(`/contract-service/api/admin/call-requests`, {}, { params }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.requests]);
      },
    }
  );
}
