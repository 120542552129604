import { IPageActions } from '@/types/common/IPageActions';

enum IEnumRClientPageActions {
  'delete',
}

export type IClientPageActions = {
  action: keyof typeof IEnumRClientPageActions;
  actionId: string;
};

export const clientsPageActions: IPageActions<IClientPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumRClientPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
