import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@utils/index';
type IStatementUpdateResponse = void;

export function useIndividualDocumentChangeStatus({
  ...params
}: {
  comment?: string;
  id: number;
  status: 'ACCEPTED' | 'REJECTED' | 'REVIEWING';
}) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<IStatementUpdateResponse>, AxiosError>(
    () =>
      api.put(
        `/contract-service/api/admin/statement-documents/change-status`,
        {},
        { params }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.statements]),
          queryClient.invalidateQueries([queryKeys.statement]);
      },
    }
  );
}
