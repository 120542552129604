import { Route, Routes } from 'react-router-dom';

import EmployeeCreatePage from './pages/EmployeeCreatePage';
import EmployeeDetailsPage from './pages/EmployeeDetailsPage';
import EmployeeEditPage from './pages/EmployeeEditPage';
import EmployeesPage from './pages/EmployeesPage';

export const EmployeesRouter = () => {
  return (
    <Routes>
      <Route element={<EmployeesPage />} index />
      <Route element={<EmployeeCreatePage />} path="/create" />
      <Route element={<EmployeeEditPage />} path="/edit/:id" />
      <Route element={<EmployeeDetailsPage />} path="/:id" />
    </Routes>
  );
};
