import { t } from 'i18next';

export type ApplicationDocumentsStatusEnumKeys =
  keyof typeof ApplicationDocumentsStatusEnum;

export enum ApplicationDocumentsStatusEnum {
  ACCEPTED = 'Обработано',
  REJECTED = 'Отклонено',
  REVIEWING = 'В обработке',
}

export enum ApplicationDocumentsStatusValueEnum {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  REVIEWING = 'REVIEWING',
}

export const getApplicationDocumentsStatuses = () => [
  {
    label: t(`statuses.ACCEPTED`),
    value: ApplicationDocumentsStatusValueEnum.ACCEPTED,
  },
  {
    label: t(`statuses.REJECTED`),
    value: ApplicationDocumentsStatusValueEnum.REJECTED,
  },
  {
    label: t(`statuses.REVIEWING`),
    value: ApplicationDocumentsStatusValueEnum.REVIEWING,
  },
];
