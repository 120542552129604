import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IInsuranceRequest } from '@/types/IInsuranceRequest';

type IStatementsResponse = IPageAbleResponse<IInsuranceRequest>;

export function useRequests({
  ...params
}: IPageAbleRequest & {
  status: 'NEW_APPLICATION' | 'PROCESSED_APPLICATION';
  type: 'LIFE' | 'OSNS' | 'INSURANCE_CASE';
}) {
  return useQuery<IStatementsResponse, AxiosError>(
    [queryKeys.requests, params],
    () =>
      api
        .get(`/contract-service/api/call-requests`, {
          params,
        })
        .then((res) => res.data)
    // {
    //   enabled:
    //     !!localStorage.getItem(storageKeys.tokenAccess),
    // }
  );
}
