import { FC, useState } from 'react';

import { useStatements } from '@api/statements/useStatements';
import { QueryTable } from '@components/organisms/QueryTable';
import {
  ApplicationsStatusEnumKeys,
  getApplicationsStatuses,
} from '@consts/enums/IEnumApplicationsStatus';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Chip, Group, Select, Text } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { statementsColumns } from '../consts/columns/statementsColumns';
import { statementsFilters } from '../consts/filters/statementsFilters';
import {
  IStatementPageActions,
  statementPageActions,
} from '../consts/pageActions/IStatementPageActions';

const StatementsPage: FC = () => {
  const [value, setValue] = useState<'COMPANY' | 'INDIVIDUAL'>('INDIVIDUAL');
  const [status, setStatus] = useState<
    string | ApplicationsStatusEnumKeys | null
  >();

  const { filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    IStatementPageActions
  >(statementsFilters, statementPageActions);

  const statementsQuery = useStatements({
    ...filtered,
    status: (status as ApplicationsStatusEnumKeys) || null,
    type: value,
  });
  const handleTabChange = (value: string) => {
    setValue(value as 'INDIVIDUAL' | 'COMPANY');
  };

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/statements', title: 'Заявления' }]}
        openMenuKeys={['statements']}
        selectedMenuKeys={['statements']}
        title="Руководство"
      />
      <div className="flex pt-6">
        <Text fw={600} mr={12} size="lg">
          Все заявления
        </Text>
        <span className="rounded-2xl bg-[#EBF3FD] px-2 text-[#1F4A62]">
          {/* {totalEmployeesCount} */}
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3">
          <div className="flex flex-col gap-4">
            <div className="col-span-3 mt-5 flex items-baseline justify-between gap-4">
              <Chip.Group
                multiple={false}
                onChange={(value) => handleTabChange(value)}
                value={value}
              >
                <Group className="gap-0" justify="start">
                  <Chip value="INDIVIDUAL" variant="outline">
                    Индивидуальные
                    {/* <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      0
                    </span> */}
                  </Chip>
                  <Chip value="COMPANY" variant="outline">
                    Компании
                    {/* <span className="ml-2 min-w-[30px] rounded-xl bg-[#F2F4F7] px-1 text-center">
                      0
                    </span> */}
                  </Chip>
                </Group>
              </Chip.Group>
            </div>
            <div className="flex items-baseline justify-end gap-4">
              <Select
                clearable
                data={getApplicationsStatuses().map((type) => ({
                  label: type.label,
                  value: type.value,
                }))}
                onChange={setStatus}
                placeholder="Статус"
                value={status}
                withAsterisk
              />
            </div>
            <div className="overflow-x-scroll rounded-xl bg-white p-5">
              <QueryTable
                columns={statementsColumns}
                data={statementsQuery.data}
                empty={{
                  description: 'В данном разделе отсутствуют загруженные файлы',
                  title: '',
                }}
                query={statementsQuery}
                tableFilter={{
                  filtered,
                  filters: statementsFilters,
                  onFilter,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatementsPage;
