export type IEnumStatementLifeReasonTypesKeys =
  keyof typeof IEnumStatementLifeReasonTypes;
export type IEnumStatementOSNSReasonTypesKeys =
  keyof typeof IEnumStatementOSNSReasonTypes;

export enum IEnumStatementLifeReasonTypes {
  LIFE_DEATH_DUE_TO_ACCIDENT = 'Смерть в результате несчастного случая',
  LIFE_DEATH_DUE_TO_ILLNESS = 'Смерть в результате болезни',
  LIFE_DISABILITY_DUE_TO_ACCIDENT = 'Установление инвалидности I, II группы в результате несчастного случая',
}

export enum IEnumStatementOSNSReasonTypes {
  OSNS_EMPLOYEE_DEATH = 'Смерть работника',
  OSNS_LOSS_OF_PROFESSIONAL_ABILITY = 'Установление степени утраты профессиональной трудоспособности'
}

export const getStatementLifeReasonType = () => [
  {
    label: 'Смерть в результате болезни',
    value: 'LIFE_DEATH_DUE_TO_ILLNESS',
  },
  {
    label: 'Смерть в результате несчастного случая',
    value: 'LIFE_DEATH_DUE_TO_ACCIDENT',
  },
  {
    label:
      'Установление инвалидности I, II группы в результате несчастного случая',
    value: 'LIFE_DISABILITY_DUE_TO_ACCIDENT',
  },
];

export const getStatementOSNSReasonType = () => [
  {
    label: 'Установление степени утраты профессиональной трудоспособности',
    value: 'OSNS_LOSS_OF_PROFESSIONAL_ABILITY',
  },
  {
    label: 'Смерть работника',
    value: 'OSNS_EMPLOYEE_DEATH',
  },
];
