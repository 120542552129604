import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import imageCompression from 'browser-image-compression';
import moment from 'moment';

import { useMutateFileUploadLink } from '@api/files/useMutateFileUploadLink';
import { useNewsPost } from '@api/news/useNewsPost';
import {
  newsUpdateInitial,
  newsUpdateValidation,
  useNewsUpdate,
} from '@api/news/useNewsUpdate';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { NewsFormProvider, useNewsForm } from '@context/FormContext';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  Button,
  Container,
  FileInput,
  Radio,
  Skeleton,
  Text,
  TextInput,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { yupResolver } from '@mantine/form';
import NewsTextEditor from '@modules/news/components/molecules/NewsTextEditor';
import { IconCalendar, IconPhoto } from '@tabler/icons-react';
import { notify } from '@utils/notify';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { CancelCreateModal } from '../components/organisms/CancelCreateModal';
import { newsFilters } from '../consts/filters/newsFilters';
import {
  INewsPageActions,
  newsPageActions,
} from '../consts/pageActions/INewsPageActions';

const NewsEditPage: FC = () => {
  const newsPostUpdate = useNewsUpdate();
  const { id = '' } = useParams<{ id: string }>();

  const uploadLink = useMutateFileUploadLink({ directory: 'news' });
  const navigate = useNavigate();

  const newsPostQuery = useNewsPost({ id: Number(id) });
  const newsPost = newsPostQuery.data?.data;

  const newsPostImageKz = newsPost?.imageKz ? newsPost?.imageKz.split('/') : '';
  const imageKz = newsPostImageKz[newsPostImageKz.length - 1];

  const newsPostImageRu = newsPost?.imageRu ? newsPost?.imageRu.split('/') : '';
  const imageRu = newsPostImageRu[newsPostImageRu.length - 1];

  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    INewsPageActions
  >(newsFilters, newsPageActions);

  const form = useNewsForm({
    initialValues: newsUpdateInitial,
    transformValues: (values) => ({
      ...values,
      createdAt: moment(values.createdAt).format('yyyy-MM-DD HH:mm:ss'),
    }),
    validate: yupResolver(newsUpdateValidation),
  });

  const [fileFormDataKz, setFileFormDataKz] = useState<FormData>();
  const [fileFormDataRu, setFileFormDataRu] = useState<FormData>();

  useEffect(() => {
    if (newsPost) {
      const {
        contentKz,
        contentRu,
        createdAt,
        id,
        imageKz,
        imageRu,
        postType,
        slugKz,
        slugRu,
        titleKz,
        titleRu,
      } = newsPost;

      form.setValues({
        contentKz,
        contentRu,
        createdAt: new Date(moment(createdAt).format('yyyy-MM-DD HH:mm:ss')),
        id,
        imageKz,
        imageRu,
        postType,
        slugKz,
        slugRu,
        titleKz,
        titleRu,
      });
    }
  }, [newsPost]);

  useEffect(() => {
    if (form.values.fileKz) {
      if (form.values.fileKz.size <= 5000000) {
        imageCompression(form.values.fileKz, {
          alwaysKeepResolution: true,
          maxSizeMB: 0.2,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        }).then((compressedFile) => {
          const formData = new FormData();
          formData.append(
            'file',
            compressedFile as File,
            `${form.values.fileKz.name}`
          );
          setFileFormDataKz(formData);
        });
      } else if (form.values.fileKz.size > 5000000) {
        form.setErrors({ fileKz: 'Файл не должен превышать 5МВ' });
      }
    }
  }, [form.values.fileKz]);

  useEffect(() => {
    if (form.values.fileRu) {
      if (form.values.fileRu.size <= 5000000) {
        imageCompression(form.values.fileRu, {
          alwaysKeepResolution: true,
          maxSizeMB: 0.2,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        }).then((compressedFile) => {
          const formData = new FormData();
          formData.append(
            'file',
            compressedFile as File,
            `${form.values.fileRu.name}`
          );
          setFileFormDataRu(formData);
        });
      } else if (form.values.fileRu.size > 5000000) {
        form.setErrors({ fileRu: 'Файл не должен превышать 5МВ' });
      }
    }
  }, [form.values.fileRu]);

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/news', title: 'Новости' },
          { link: '/news/edit', title: 'Редактировать новость' },
        ]}
        openMenuKeys={['news']}
        selectedMenuKeys={['news']}
        title="Редактировать новость"
      />
      <NewsFormProvider form={form}>
        <Container className="my-10">
          {newsPostQuery.isLoading ? (
            <>
              <Skeleton className="h-56 rounded-xl" />
            </>
          ) : !newsPost ? (
            <NotFoundResult subTitle="" title="Ничего не найдено" />
          ) : (
            <form
              onSubmit={form.onSubmit((values) => {
                if (fileFormDataKz && fileFormDataRu) {
                  uploadLink
                    .mutateAsync({
                      file: fileFormDataKz,
                      fileName: values.fileKz.name,
                    })
                    .then((responseKz) => {
                      return uploadLink
                        .mutateAsync({
                          file: fileFormDataRu,
                          fileName: values.fileRu.name,
                        })
                        .then((responseRu) => {
                          return newsPostUpdate
                            .mutateAsync({
                              ...values,
                              createdAt: moment(values.createdAt).format(
                                'yyyy-MM-DD HH:mm:ss'
                              ),
                              id: Number(id),
                              imageKz: `${responseKz.data.url}`,
                              imageRu: `${responseRu.data.url}`,
                            })
                            .then(() => {
                              notify('success', 'Изменения сохранены!');
                              navigate('/news');
                            })
                            .catch(() => {
                              notify(
                                'error',
                                'Что-то пошло не так, повторите попытку позже.'
                              );
                            });
                        })
                        .catch(() => {
                          notify(
                            'error',
                            'Что-то пошло не так, повторите попытку позже.'
                          );
                        });
                    })
                    .catch(() => {
                      notify(
                        'error',
                        'Что-то пошло не так, повторите попытку позже.'
                      );
                    });
                } else {
                  newsPostUpdate
                    .mutateAsync({
                      ...values,
                      id: Number(id),
                    })
                    .then(() => {
                      notify('success', 'Изменения сохранены!');
                      navigate('/news');
                    })
                    .catch(() => {
                      notify(
                        'error',
                        'Что-то пошло не так, повторите попытку позже.'
                      );
                    });
                }
              })}
            >
              <div>
                <Text fw={700} mb={20} size="lg">
                  Редактировать новость
                </Text>
                <div className="grid grid-cols-2 gap-2">
                  <Radio.Group
                    className="col-span-1"
                    label="Тип публикации"
                    withAsterisk
                    {...form.getInputProps(`postType`)}
                  >
                    <Radio key="news" label="Новость" value="NEWS" />
                    <Radio
                      key="article"
                      label="Полезная статья"
                      mt={10}
                      value="USEFUL_ARTICLE"
                    />
                  </Radio.Group>

                  <DateInput
                    className="col-span-1"
                    label="Дата публикации"
                    locale="ru"
                    rightSection={<IconCalendar />}
                    valueFormat="DD MMMM YYYY"
                    withAsterisk
                    {...form.getInputProps(`createdAt`)}
                  />
                </div>
              </div>

              <div className="border-1 mt-6 rounded-xl border bg-white p-6">
                <Text fw={700} size="md">
                  Версия на казахском языке
                </Text>
                <TextInput
                  className="mt-4"
                  label="Заголовок новости"
                  name="title"
                  withAsterisk
                  {...form.getInputProps('titleKz')}
                />
                <FileInput
                  accept="image/png,image/jpeg,image/jpg"
                  className="mt-4"
                  clearable
                  label="Загрузить картинку"
                  leftSection={<IconPhoto stroke="1.2" />}
                  placeholder={imageKz}
                  withAsterisk
                  {...form.getInputProps('fileKz')}
                />
                <Text c="dimmed" my={8} size="xs">
                  Допустимые форматы загрузки файлов: jpg, jpeg, png. Размер
                  загружаемого файла не должен превышать 5 MB.
                </Text>

                <div className="mt-5">
                  <Text color="#868E96" mb={8} size="sm">
                    Содержание новости
                  </Text>

                  <NewsTextEditor
                    content={newsPost.contentKz}
                    lang="kz"
                    placeholder="Содержание..."
                    type="contentKz"
                  />
                </div>
              </div>

              <div className="border-1 mt-10 rounded-xl border bg-white p-6">
                <Text fw={700} size="md">
                  Версия на русском языке
                </Text>

                <TextInput
                  className="mt-4"
                  label="Заголовок новости"
                  name="title"
                  withAsterisk
                  {...form.getInputProps('titleRu')}
                />
                <FileInput
                  accept="image/png,image/jpeg"
                  className="mt-4"
                  clearable
                  label="Загрузить картинку"
                  leftSection={<IconPhoto stroke="1.2" />}
                  placeholder={imageRu}
                  withAsterisk
                  {...form.getInputProps('fileRu')}
                />
                <Text c="dimmed" className="text-gray-7" mt={8} size="xs">
                  Допустимые форматы загрузки файлов: jpg, jpeg, png. Размер
                  загружаемого файла не должен превышать 5 MB.
                </Text>

                <div className="mt-5">
                  <Text color="#868E96" mb={8} size="sm">
                    Содержание новости
                  </Text>
                  <NewsTextEditor
                    content={newsPost.contentRu}
                    lang="ru"
                    placeholder="Содержание..."
                    type="contentRu"
                  />
                </div>
              </div>

              <div className="flex flex-row justify-end">
                <Button
                  className="mt-4"
                  onClick={() => {
                    setPartialActioned({
                      action: 'cancelCreate',
                    });
                  }}
                  variant="outline"
                >
                  Отменить
                </Button>
                <Button
                  className="ml-4 mt-4"
                  disabled={!form.isDirty()}
                  loading={newsPostUpdate.isLoading}
                  type="submit"
                  variant="outlined"
                >
                  Опубликовать
                </Button>
              </div>
            </form>
          )}

          <CancelCreateModal
            isOpen={actioned.action === 'cancelCreate'}
            onClose={clearParams}
          />
        </Container>
      </NewsFormProvider>
    </div>
  );
};

export default NewsEditPage;
