import { useTranslation } from 'react-i18next';

import SafetyIcon from '@components/atoms/icons/SafetyIcon';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, Text, Tooltip } from '@mantine/core';
import { contractsFilters } from '@modules/clients/consts/filters/contractsFilters';
import {
  contractsPageActions,
  IContractsPageActions,
} from '@modules/clients/consts/pageActions/IContractsPageActions';

// import { ApplicationStatusTag } from '@modules/applications/components/atoms/ApplicationStatusTag';
import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IContract } from '@/types/IContract';

export default function ContractCard({ contract }: { contract: IContract }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IContractsPageActions
  >(contractsFilters, contractsPageActions);

  return (
    <div className="delay-80 flex max-w-[590px] flex-col justify-between rounded-[32px] bg-white p-8 shadow-sm transition ease-in-out hover:shadow-md">
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-6">
          <SafetyIcon stroke="1.2" style={{ height: '55px', width: '55px' }} />
          <Tooltip
            label={
              language == 'kz'
                ? contract.contractNameKz
                : contract.contractNameRu
            }
          >
            <Text className="truncate" color="#495057" fw={800} size="lg">
              {(language == 'kz'
                ? contract.contractNameKz
                : contract.contractNameRu) ?? '-'}{' '}
            </Text>
          </Tooltip>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-5 py-8 sm:grid-cols-2">
        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Застрахованный:
          </Text>
          {/* FIXME: add actual data */}
          <Text color="#464C5D" fw={500} size="md">
            Сулейманова Анара Ф.
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Номер договора:
          </Text>
          <Text color="#6D758F" fw={600} size="sm">
            № {contract.contractNumber}
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Страховая сумма:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {contract.sumInsured || '-'}
          </Text>
        </div>

        <div>
          <Text color="#6D758F" fw={400} size="sm">
            Страховая премия:
          </Text>
          <Text color="#464C5D" fw={500} size="md">
            {contract.premiumInsurance || '-'}
          </Text>
        </div>
      </div>

      <div className="flex w-full items-center justify-between border-t pt-8">
        <Button
          fullWidth
          onClick={() => {
            setPartialActioned({
              action: 'create',
              actionId: String(contract.contractNumber) ?? '',
              premiumInsurance: String(contract.premiumInsurance) ?? '',
              sumInsured: String(contract.sumInsured) ?? '',
              type: 'INDIVIDUAL',
            });
          }}
          variant="filled"
        >
          Создать уведомление
        </Button>
      </div>
    </div>
  );
}
