import { type FC } from 'react';

import { useFilesDelete } from '@api/files/useFileDelete';
import { Button, Modal } from '@mantine/core';

type DeleteAttributeModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmDeleteModal: FC<DeleteAttributeModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const deleteFile = useFilesDelete();

  const handleSubmit = () => {
    deleteFile.mutateAsync({ id: Number(id) }).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Удалить файл"
    >
      <div className="m-6 mx-auto mt-0 max-w-[410px] text-center font-semibold text-gray-600">
        {`Вы уверены, что хотите удалить загруженный файл?`}
      </div>

      <div className="flex justify-end gap-4 pt-0">
        <Button className="!w-[150px]" onClick={onClose} variant="subtle">
          Нет
        </Button>
        <Button
          className="!w-[150px]"
          loading={deleteFile.isLoading}
          onClick={handleSubmit}
        >
          Да
        </Button>
      </div>
    </Modal>
  );
};
