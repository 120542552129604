import { FC } from 'react';

import { CloseButton, Input } from '@mantine/core';

export const Search: FC<{
  onChange: (value: string) => void;
  searchQuery: string;
}> = ({ onChange, searchQuery = '' }) => {
  return (
    <Input
      className="w-[300px]"
      mt="md"
      onChange={(event) => onChange(event.currentTarget.value)}
      placeholder="Поиск..."
      rightSection={
        <CloseButton
          aria-label="Очистить"
          onClick={() => onChange('')}
          style={{ display: searchQuery ? undefined : 'none' }}
        />
      }
      rightSectionPointerEvents="all"
      value={searchQuery}
    />
  );
};
