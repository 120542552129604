import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import { Badge, Text } from '@mantine/core';

import { IInsuranceRequest } from '@/types/IInsuranceRequest';

export const requestsColumns: MRT_ColumnDef<IInsuranceRequest>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: 'ID',
  },
  {
    accessorFn: (row) => (
      <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
        {row.fio ?? '-'}
      </Text>
    ),
    accessorKey: 'fio',
    header: 'ФИО',
  },
  {
    accessorFn: (row) => (
      <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
        {row.phone ?? '-'}
      </Text>
    ),
    accessorKey: 'phone',
    header: 'Номер телефона',
  },
  {
    accessorFn: (row) => (
      <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
        {row.identifier ?? '-'}
      </Text>
    ),
    accessorKey: 'identifier',
    header: 'ИИН/БИН',
  },
  {
    accessorFn: (row) => (
      <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
        {row.email ?? '-'}
      </Text>
    ),
    accessorKey: 'email',
    header: 'Почта',
  },
  {
    accessorFn: (row) => (
      <Text className="w-[200px] truncate whitespace-nowrap" size="sm">
        {row.info ?? '-'}
      </Text>
    ),
    accessorKey: 'info',
    header: 'Доп. сведения',
  },
  {
    accessorFn: (row) => (
      <Badge
        classNames={{
          label: `${row.status == 'NEW_APPLICATION' ? 'text-[#0F62FE]' : 'text-[#027A48]'}`,
          root: `${row.status == 'NEW_APPLICATION' ? '!bg-[#E4F0FF]' : '!bg-[#ECFDF3]'} !border-none`,
        }}
        color={`${row.status == 'NEW_APPLICATION' ? 'blue' : 'green'}`}
        variant="dot"
      >
        <div className="flex items-center justify-center">
          {t(`statuses.${row.status}`)}
        </div>
      </Badge>
    ),
    accessorKey: 'status',
    header: 'Статус',
  },
];
