import { type FC, useState } from 'react';

import { useIndividualDocumentChangeStatus } from '@api/statements/useStatementDocumentChangeStatus';
import {
  ApplicationDocumentsStatusEnumKeys,
  getApplicationDocumentsStatuses,
} from '@consts/enums/IEnumApplicationDocumentsStatus';
import { Button, Modal, Select, Textarea } from '@mantine/core';

type ChangeStatusModalProps = {
  fileStatus: ApplicationDocumentsStatusEnumKeys;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeStatusModal: FC<ChangeStatusModalProps> = ({
  fileStatus,
  id = '',
  isOpen,
  onClose,
}) => {
  const [status, setStatus] = useState<string | null>();
  const [comment, setComment] = useState('');

  const changeDocumentStatus = useIndividualDocumentChangeStatus({
    comment: comment,
    id: +id,
    status: status as ApplicationDocumentsStatusEnumKeys,
  });

  const handleSubmit = () => {
    changeDocumentStatus.mutateAsync().then(() => onClose());
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Cменить статус документа"
    >
      <div className="mb-10">
        <Select
          clearable
          comboboxProps={{ withinPortal: false }}
          data={getApplicationDocumentsStatuses().map((type) => ({
            label: type.label,
            value: type.value,
          }))}
          defaultValue={fileStatus}
          onChange={setStatus}
          placeholder="Статус"
          value={status}
          withAsterisk
        />
        {status == 'REJECTED' && (
          <Textarea
            className="mt-3"
            minRows={6}
            onChange={(event) => setComment(event.currentTarget.value)}
            placeholder="Укажите причину отказа..."
            value={comment}
          />
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <Button onClick={onClose} variant="outline">
          Нет
        </Button>
        <Button loading={changeDocumentStatus.isLoading} onClick={handleSubmit}>
          Да
        </Button>
      </div>
    </Modal>
  );
};
