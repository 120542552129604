import { FC } from 'react';

import { useEmployeesFormContext } from '@context/FormContext';
import { RichTextEditor } from '@mantine/tiptap';
import { Link } from '@mantine/tiptap';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

const EmployeesTextEditor: FC<{
  content?: string | null;
  lang: string;
  placeholder: string;
  type: string;
}> = ({ content = '', lang, placeholder, type, ...props }) => {
  const form = useEmployeesFormContext();

  const editor = useEditor({
    content,
    extensions: [
      StarterKit,
      Underline,
      OrderedList,
      BulletList,
      Link,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Placeholder.configure({ placeholder: placeholder }),
    ],
    /* eslint-disable react/prop-types */
    onUpdate(props) {
      const content = props.editor.getHTML();
      form.setFieldValue(`${type}`, content);
    },
  });

  return (
    <RichTextEditor
      editor={editor}
      labels={{
        boldControlLabel: 'Жирный шрифт',
        italicControlLabel: 'Курсив',
        linkEditorExternalLink: 'Открывать ссылку в новой вкладке',
        linkEditorInternalLink: 'Открывать ссылку в этой же вкладке',
        linkEditorSave: 'Сохранить',
      }}
      {...props}
    >
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Undo />
          <RichTextEditor.Redo />
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

export default EmployeesTextEditor;
