import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IUser } from '@/types/IUser';

type IClientResponse = IUser;

export function useClient({ ...params }: { id: number }) {
  return useQuery<IClientResponse, AxiosError>(
    [queryKeys.client, params],
    () =>
      api.get(`/user-service/api/users/${params.id}`).then((res) => res.data)
    // {
    //   enabled:
    //     !!localStorage.getItem(storageKeys.tokenAccess),
    // }
  );
}
