import { Navigate } from 'react-router-dom';

import { useCurrent } from '@api/auth/useCurrent';
import { storageKeys } from '@consts/storageKeys';

const AuthRoute = ({
  auth,
  children,
}: {
  auth: boolean;
  children: JSX.Element;
}) => {
  const meQuery = useCurrent();

  if (
    auth &&
    (!localStorage.getItem(storageKeys.tokenAccess) ||
      meQuery.error?.response?.status == 401)
  ) {
    if (!localStorage.getItem(storageKeys.tokenAccess)) {
      return <Navigate replace to="/login" />;
    }
  }

  if (!auth && localStorage.getItem(storageKeys.tokenAccess)) {
    return <Navigate replace to="/" />;
  }

  return children;
};

export default AuthRoute;
