import { MantineThemeOverride } from '@mantine/core';
import { colors } from './colors';


export const mantineTheme: MantineThemeOverride = {
  components: {
    Input: {
      defaultProps: { variant: 'filled' },
      styles: () => ({
        input: {
          '&:disabled': { border: 'none' },
          '&:focusWithin': {
            backgroundColor: colors.primary['102'],
            borderColor: colors.primary['400'],
          },
          '&:hover': { backgroundColor: colors.primary['102'], borderColor: colors.primary['400'] },
          '&[dataError]': { borderColor: colors.primary['900'] },
          backgroundColor: 'white',
          borderColor: colors.gray['200'],
          borderRadius: 8,
          color: colors.primary['900'],
          height: '40px',
        },
      }),
    },
    InputWrapper: {
      defaultProps: { inputWrapperOrder: ['label', 'input', 'error', 'description'] },
      styles: (theme) => ({
        description: {
          color: theme.colors.gray[5],
          fontSize: '12px',
          fontWeight: 400,
          marginTop: '8px',
        },
        label: { color: theme.colors.gray[6], marginBottom: '4px' },
      }),
    },
    Modal: {
      defaultProps: { closeButtonProps: { iconSize: 24 } },
      styles: () => ({
        body: { overflowY: 'auto', padding: '24px' },
        content: { borderRadius: '12px', display: 'flex', flexDirection: 'column' },
        header: {
          padding: '24px',
          paddingBottom: 0,
        },
        inner: { zIndex: 501 },
        overlay: { zIndex: 500 },
        title: { fontSize: '18px', fontWeight: 600, width: '100%', color: '#228BE6' },
      }),
    },
    Navbar: { styles: () => ({ root: { padding: 0 } }) },
    PasswordInput: { styles: () => ({ innerInput: { height: '100%' }, 
    // section: {
    //   left: '12px'
    // }
   }) },
    Popover: {
      styles: () => ({
        dropdown: {
          'zIndex': '1001 !important',
        },
      }),
    },
    Accordion: {
      styles: () => ({
        item: {
          marginBottom: '24px',
        },
        control: {
          '&:hover': { borderRadius: '0.75rem !important'},
        },
        label: {
          padding: 0
        }
      }),
    },
    RadioGroup: {
      styles: () => ({
        error: { marginLeft: '12px', marginTop: '12px' },
      }),
    },
    Table: {
      styles: () => ({ table: { 
        background: 'white', 
        borderRadius:'12px' 
      } }),
    },
    Select: {
      // classNames: { input: 'bg-white' },
      styles: () => ({ dropdown: { 'zIndex': '20' } }),
    },
    Checkbox: {
      styles: () => ({ icon: { width: '50%' }, inner: { marginRight: '2px' } }),
    },
    Switch: {
      styles: () => ({
        label: { color: '#6B7280', fontSize: '14px', fontWeight: 'normal' },
        thumb: { 
          border: 'none'
        },
        track: {
          '&:checked': { background: '#5D70FF' },
          border: 'none',
          minWidth: 0,
        },
      }),
    },
    NavLink: {
        styles: () => ({
          children: { '.mantineNavLinkRoot': { paddingLeft: '3rem' } },
          root: {
            borderRadius: '8px',
          },
        }),
    },
    TimeInput: { styles: () => ({ input: { height: '31px !important' } }) },
    ActionIcon: { styles: () => ({ root: { color: '#8E8E93', '&:hover': { color: '#397BFF !important' }, } }) },
    Chip: { styles: () => ({ 
    iconWrapper: {
      display: 'none'
    }
    })},
  },
  fontFamily: 'Inter, sans-serif',
  fontFamilyMonospace: 'Inter, monospace',
  headings: {
    fontWeight: '600',
    sizes: {
      h1: { fontSize: '36px' },
      h2: { fontSize: '24px' },
      h3: { fontSize: '18px' },
    },
  },
};
