import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { INewsPost } from '@/types/INewsPost';

type INewsPostRequest = { id: number };

type INewsPostResponse = AxiosResponse<INewsPost>;

export function useNewsPost(params: INewsPostRequest) {
  return useQuery<INewsPostResponse, AxiosError>(
    [queryKeys.newsPost, params],
    () => api.get(`/admin/api/news/${params.id}`),
    { enabled: !!params.id }
  );
}
