import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { t } from 'i18next';

import { useEmployeeDelete } from '@api/employees/useEmployeeDelete';
import { Button, Modal } from '@mantine/core';
import { notify } from '@utils/notify';

type DeleteAttributeModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmDeleteModal: FC<DeleteAttributeModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const deleteEmployee = useEmployeeDelete();

  const handleSubmit = () => {
    deleteEmployee
      .mutateAsync({ id: Number(id) })
      .then(() => {
        onClose();
        navigate('/employees');
      })
      .catch((error) => {
        notify('error', t('messages.error'));
      });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Удалить информацию о сотруднике"
    >
      <div className="m-6 mx-auto mt-0 max-w-[410px] text-center font-semibold text-gray-600">
        {`Вы уверены, что хотите удалить публикацию о сотруднике?`}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <Button onClick={onClose} variant="outline">
          Нет
        </Button>
        <Button loading={deleteEmployee.isLoading} onClick={handleSubmit}>
          Да
        </Button>
      </div>
    </Modal>
  );
};
