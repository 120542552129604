import { AxiosError } from 'axios';

import { IEnumEmployeeTypesKeys } from '@consts/enums/IEnumEmployeeTypes';
import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IEmployee } from '@/types/IEmployee';

type IEmployeesResponse = IPageAbleResponse<IEmployee>;

export function useEmployees({
  ...params
}: IPageAbleRequest & {
  category?: IEnumEmployeeTypesKeys;
  searchQuery?: string;
}) {
  return useQuery<IEmployeesResponse, AxiosError>(
    [queryKeys.employees, params],
    () =>
      api
        .get(`/admin/api/employees`, {
          params,
        })
        .then((res) => res.data)
    // {
    //   enabled:
    //     !!localStorage.getItem(storageKeys.tokenAccess),
    // }
  );
}
