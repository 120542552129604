import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import imageCompression from 'browser-image-compression';
import moment from 'moment';

import {
  filesUploadInitial,
  filesUploadValidation,
  useFilesUpload,
} from '@api/files/useFilesUpload';
import { useMutateFileUploadLink } from '@api/files/useMutateFileUploadLink';
import {
  getCorporateFileType,
  getCorporateFileYearType,
  getFileType,
} from '@consts/enums/IEnumFileTypes';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  Button,
  Container,
  FileInput,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconPhoto } from '@tabler/icons-react';
import { notify } from '@utils/notify';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { CancelCreateModal } from '../components/organisms/CancelCreateModal';
import { filesFilters } from '../consts/filters/filesFilters';
import {
  filesPageActions,
  IFilesPageActions,
} from '../consts/pageActions/IFilesPageActions';

const FilesUploadPage: FC = () => {
  const navigate = useNavigate();
  const filesUpload = useFilesUpload();

  const [fileFormDataKz, setFileFormDataKz] = useState<FormData>();
  const [fileFormDataRu, setFileFormDataRu] = useState<FormData>();

  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IFilesPageActions
  >(filesFilters, filesPageActions);

  const form = useForm({
    initialValues: filesUploadInitial,
    transformValues: (values) => ({
      ...values,
      fileNameKz: `${form.values.fileNameKz} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`,
      fileNameRu: `${form.values.fileNameRu} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`,
      subcategory: `${['REMUNERATION_INSURANCE_AGENTS', 'REGISTER_OF_INSURANCE_AGENTS', 'INFORMATION_ABOUT_PARTICIPATION_ASSOCIATIONS_UNIONS'].includes(form.values.category) ? '' : form.values.subcategory}`,
    }),
    validate: yupResolver(filesUploadValidation),
  });

  const uploadLink = useMutateFileUploadLink({
    directory: `${['REMUNERATION_INSURANCE_AGENTS', 'REGISTER_OF_INSURANCE_AGENTS', 'INFORMATION_ABOUT_PARTICIPATION_ASSOCIATIONS_UNIONS'].includes(form.values.category) ? 'files' : `files-${form.values.category.replace('_', '-').toLocaleLowerCase()}`}`,
  });

  useEffect(() => {
    if (form.values.fileFieldKz) {
      if (
        form.values.fileFieldKz.size <= 10000000 &&
        form.values.fileFieldKz.type != 'application/pdf'
      ) {
        imageCompression(form.values.fileFieldKz, {
          alwaysKeepResolution: true,
          maxSizeMB: 2,
          useWebWorker: true,
        }).then((compressedFile) => {
          const formData = new FormData();
          formData.append(
            'file',
            compressedFile as File,
            `${form.values.fileNameKz}.${form.values.fileFieldKz.type.split('/')[1]}`
          );
          setFileFormDataKz(formData);
        });
      } else if (
        form.values.fileFieldKz.size <= 10000000 &&
        form.values.fileFieldKz.type == 'application/pdf'
      ) {
        const formData = new FormData();
        formData.append(
          'file',
          form.values.fileFieldKz as File,
          `${form.values.fileNameKz}.${form.values.fileFieldKz.type.split('/')[1]}`
        );
        setFileFormDataKz(formData);
      } else if (form.values.fileFieldKz.size > 10000000) {
        form.setErrors({ fileFieldKz: 'Файл не должен превышать 10МВ' });
      }
    }
  }, [form.values.fileFieldKz, form.values.fileNameKz]);

  useEffect(() => {
    if (form.values.fileFieldRu) {
      if (
        form.values.fileFieldRu.size <= 10000000 &&
        form.values.fileFieldRu.type != 'application/pdf'
      ) {
        imageCompression(form.values.fileFieldRu, {
          alwaysKeepResolution: true,
          maxSizeMB: 2,
          useWebWorker: true,
        }).then((compressedFile) => {
          const formData = new FormData();
          formData.append(
            'file',
            compressedFile as File,
            `${form.values.fileNameKz}.${form.values.fileFieldRu.type.split('/')[1]}`
          );
          setFileFormDataRu(formData);
        });
      } else if (
        form.values.fileFieldRu.size <= 10000000 &&
        form.values.fileFieldRu.type == 'application/pdf'
      ) {
        const formData = new FormData();
        formData.append(
          'file',
          form.values.fileFieldRu as File,
          `${form.values.fileNameRu}.${form.values.fileFieldRu.type.split('/')[1]}`
        );
        setFileFormDataRu(formData);
      } else if (form.values.fileFieldRu.size > 10000000) {
        form.setErrors({ fileFieldRu: 'Файл не должен превышать 10МВ' });
      }
    }
  }, [form.values.fileFieldRu, form.values.fileNameRu]);

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/files', title: 'Файлы' },
          { link: '/files/upload', title: 'Загрузить файлы' },
        ]}
        openMenuKeys={['files']}
        selectedMenuKeys={['files']}
        title="Загрузить файлы"
      />
      <Container className="my-10">
        <form
          onSubmit={form.onSubmit((values) => {
            if (fileFormDataKz && fileFormDataRu) {
              uploadLink
                .mutateAsync({
                  file: fileFormDataKz as FormData,
                  fileName: values.fileNameKz,
                })
                .then((responseKz) => {
                  return uploadLink
                    .mutateAsync({
                      file: fileFormDataRu as FormData,
                      fileName: values.fileNameRu,
                    })
                    .then((responseRu) => {
                      return filesUpload
                        .mutateAsync({
                          ...values,
                          fileKz: `${responseKz.data.url}`,
                          fileRu: `${responseRu.data.url}`,
                        })
                        .then(() => {
                          notify('success', 'Файлы успешно загружены!');
                          navigate('/files');
                        })
                        .catch(() => {
                          notify(
                            'error',
                            'Что-то пошло не так, повторите попытку позже.'
                          );
                        });
                    })
                    .catch(() => {
                      notify(
                        'error',
                        'Что-то пошло не так, повторите попытку позже.'
                      );
                    });
                })
                .catch(() => {
                  notify(
                    'error',
                    'Что-то пошло не так, повторите попытку позже.'
                  );
                });
            }
          })}
        >
          <div>
            <Text fw={700} mb={20} size="lg">
              Загрузить файлы
            </Text>
            <div className="grid grid-cols-2 gap-2">
              <Select
                allowDeselect={false}
                data={getFileType().map((type) => ({
                  label: type.label,
                  value: type.value,
                }))}
                label="Категория"
                withAsterisk
                {...form.getInputProps('category')}
              />
              {form.values.category == 'FINANCIAL_STATEMENTS' && (
                <Select
                  className="col-span-1"
                  data={getCorporateFileYearType().map((type) => ({
                    label: type.label,
                    value: type.value,
                  }))}
                  label="Подкатегория корпоративного документа"
                  placeholder="Подкатегория"
                  searchable
                  withAsterisk
                  {...form.getInputProps('subcategory')}
                />
              )}
              {form.values.category == 'CORPORATE_DOCUMENTS' && (
                <Select
                  className="col-span-1"
                  data={getCorporateFileType().map((type) => ({
                    label: type.label,
                    value: type.value,
                  }))}
                  label="Подкатегория корпоративного документа"
                  placeholder="Подкатегория"
                  withAsterisk
                  {...form.getInputProps('subcategory')}
                />
              )}
            </div>
          </div>

          <div className="border-1 mt-6 rounded-xl border bg-white p-6">
            <Text fw={700} size="md">
              Версия на казахском языке
            </Text>
            <Text c="dimmed">{`${form.values.fileNameKz} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`}</Text>
            <div className="grid grid-cols-2 items-baseline gap-4">
              <TextInput
                className="mt-4"
                label="Наименование файла"
                name="fileNameKz"
                withAsterisk
                {...form.getInputProps('fileNameKz')}
              />
              <div>
                <FileInput
                  accept="application/pdf,image/png,image/jpeg,image/jpg"
                  className="mt-4"
                  clearable
                  label="Выберите файл"
                  leftSection={<IconPhoto stroke="1.2" />}
                  withAsterisk
                  {...form.getInputProps('fileFieldKz')}
                />
                <Text c="dimmed" className="text-gray-7" my={8} size="xs">
                  Допустимые форматы загрузки файлов: pdf, jpg, jpeg, png.
                  Размер загружаемого файла не должен превышать 10 MB.
                </Text>
              </div>
            </div>
          </div>

          <div className="border-1 mt-10 rounded-xl border bg-white p-6">
            <Text fw={700} size="md">
              Версия на русском языке
            </Text>
            <Text c="dimmed">{`${form.values.fileNameRu} (последнее изменение от ${moment(new Date()).format('DD.MM.YYYY')})`}</Text>
            <div className="grid grid-cols-2 items-baseline gap-4">
              <TextInput
                className="mt-4"
                label="Наименование файла"
                name="fileNameRu"
                withAsterisk
                {...form.getInputProps('fileNameRu')}
              />
              <div>
                <FileInput
                  accept="application/pdf,image/png,image/jpeg,image/jpg"
                  className="mt-4"
                  clearable
                  label="Выберите файл"
                  leftSection={<IconPhoto stroke="1.2" />}
                  withAsterisk
                  {...form.getInputProps('fileFieldRu')}
                />
                <Text c="dimmed" className="text-gray-7" my={8} size="xs">
                  Допустимые форматы загрузки файлов: pdf, jpg, jpeg, png.
                  Размер загружаемого файла не должен превышать 10 MB.
                </Text>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-end">
            <Button
              className="mt-4"
              onClick={() => {
                setPartialActioned({
                  action: 'cancelCreate',
                });
              }}
              variant="outline"
            >
              Отменить
            </Button>
            <Button
              className="ml-4 mt-4"
              disabled={!form.isDirty()}
              loading={filesUpload.isLoading || uploadLink.isLoading}
              type="submit"
              variant="outlined"
            >
              Опубликовать
            </Button>
          </div>
        </form>

        <CancelCreateModal
          isOpen={actioned.action === 'cancelCreate'}
          onClose={clearParams}
        />
      </Container>
    </div>
  );
};

export default FilesUploadPage;
