import { IPageActions } from '@/types/common/IPageActions';

enum IEnumContractsPageActions {
  'create',
}

export type IContractsPageActions = {
  action: keyof typeof IEnumContractsPageActions;
  actionId: string;
  contractNumber: string;
  premiumInsurance: string;
  sumInsured: string;
  type: 'INDIVIDUAL' | 'COMPANY';
};

export const contractsPageActions: IPageActions<IContractsPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumContractsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
  contractNumber: { default: '' },
  premiumInsurance: { default: '' },
  sumInsured: { default: '' },
  type: { default: 'INDIVIDUAL' },
};
