import i18n from 'i18next';
import {
  array,
  boolean,
  date,
  LocaleObject,
  mixed,
  number,
  object,
  ObjectSchema,
  setLocale,
  string,
} from 'yup';
import { ru } from 'yup-locales';

const enLocale: LocaleObject = {
  mixed: {
    notType: 'This field is required',
    required: 'This field is required',
  },
};
const ruLocale: LocaleObject = {
  mixed: {
    notType: 'Обязательно для заполнения',
    required: 'Обязательно для заполнения',
  },
};

setLocale({ ...ru, ...(i18n.language == 'en' ? enLocale : ruLocale) });

export { ObjectSchema, array, boolean, date, mixed, number, object, string };
