import { AxiosError } from 'axios';

import { IEnumNewsTypesKeys } from '@consts/enums/IEnumNewsTypes copy';
import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { INewsPost } from '@/types/INewsPost';

type IAttributesResponse = IPageAbleResponse<INewsPost>;

export function useNews({
  ...params
}: IPageAbleRequest & {
  postType?: IEnumNewsTypesKeys;
  searchQuery?: string;
}) {
  return useQuery<IAttributesResponse, AxiosError>(
    [queryKeys.news, params],
    () =>
      api
        .get(`/admin/api/news`, {
          params,
        })
        .then((res) => res.data)
    // {
    //   enabled:
    //     !!localStorage.getItem(storageKeys.tokenAccess),
    // }
  );
}
