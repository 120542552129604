import { FC, useMemo } from 'react';

import { t } from 'i18next';

import { ApplicationDocumentsStatusEnumKeys } from '@consts/enums/IEnumApplicationDocumentsStatus';
import { ApplicationsStatusEnumKeys } from '@consts/enums/IEnumApplicationsStatus';
import { Badge, BadgeProps } from '@mantine/core';

type StatusProps = BadgeProps & {
  readonly status:
    | ApplicationsStatusEnumKeys
    | ApplicationDocumentsStatusEnumKeys;
  readonly variant?: 'default' | 'mantine';
};

export const ApplicationStatusTag: FC<StatusProps> = ({
  status,
  variant = 'default',
}: StatusProps) => {
  const labelClassName = useMemo(() => {
    switch (status) {
      case 'STATEMENT_ACCEPTED':
      case 'ACCEPTED':
        return 'text-[#027A48]';
      case 'REJECTED':
        return 'text-[#FF4545]';
      case 'IN_PROCESSING_DOCUMENTS':
      case 'IN_PROCESSING_PAYMENT':
      case 'REVIEWING':
        return 'text-[#0F62FE]';
      case 'WAITING_FOR_DOCUMENTS':
      case 'WAITING_FOR_PAYMENT_APPLICATION':
        return 'text-[#FD7E14]';
      case 'PAYMENT_SENT':
        return 'text-[#0B7B94]';
      default:
        return '';
    }
  }, [status]);

  const rootClassName = useMemo(() => {
    switch (status) {
      case 'STATEMENT_ACCEPTED':
      case 'ACCEPTED':
        return '!bg-[#ECFDF3] !border-none';
      case 'REJECTED':
        return '!bg-[#FFDEDE] !border-none';
      case 'IN_PROCESSING_DOCUMENTS':
      case 'IN_PROCESSING_PAYMENT':
      case 'REVIEWING':
        return '!bg-[#E4F0FF] !border-none';
      case 'WAITING_FOR_DOCUMENTS':
      case 'WAITING_FOR_PAYMENT_APPLICATION':
        return '!bg-[#FFE5CF] !border-none';
      case 'PAYMENT_SENT':
        return '!bg-[#D5F4FB] !border-none';
      default:
        return '';
    }
  }, [status]);

  const statusColor = useMemo(() => {
    switch (status) {
      case 'STATEMENT_ACCEPTED':
      case 'ACCEPTED':
        return 'green';
      case 'REJECTED':
        return 'red';
      case 'IN_PROCESSING_DOCUMENTS':
      case 'IN_PROCESSING_PAYMENT':
      case 'REVIEWING':
        return 'blue';
      case 'WAITING_FOR_DOCUMENTS':
      case 'WAITING_FOR_PAYMENT_APPLICATION':
        return 'yellow';
      case 'PAYMENT_SENT':
        return '#0B7B94';
      default:
        return '';
    }
  }, [status]);

  return (
    <Badge
      classNames={{
        label: labelClassName,
        root: rootClassName,
      }}
      color={statusColor}
      variant="dot"
    >
      <div className="flex items-center justify-center">
        {t(`statuses.${status}`)}
      </div>
    </Badge>
  );
};
